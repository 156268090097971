<template>
	<b-row>
		<b-col cols="12" xl="12">
			<MonthSelect :DateDefaultSelect="DateDefaultSelect" @setDateDefaultSelect="setDateDefaultSelect" />
		</b-col>
		<b-col cols="12" xl="12">
			<b-table-simple small caption-top responsive sticky-header="600px" sticky-column="first" ref="my-table">
				<b-thead>
					<b-tr>
						<b-th
							class="text-center middle"
							style="position: sticky; left: 0; z-index: 3; background-color: #00a09c; border: 1px solid #dee2e6; color: #ffff"
						>
							<div>{{ this.FormMSG(1, 'Crew member') }}</div>
						</b-th>
						<b-th
							class="text-center middle"
							style="background-color: #00a09c; border: 1px solid #dee2e6; color: #ffff"
							v-for="item of tsfields.slice(1)"
							:key="generateSecureId(`${item.key}`)"
						>
							<div>{{ item.label.split` `[0] }}</div>
							<div>{{ item.label.split` `[1] }}</div>
						</b-th>
					</b-tr>
				</b-thead>
				<b-tbody ref="tableRef" @mousedown="startScrollingLeft" @mouseup="stopScrollingLeft">
					<b-tr v-show="setAllTimesheets.length === 0">
						<b-td
							:colspan="tsfields.length === 0 ? 1 : tsfields.length"
							class="text-center border-left init-background"
							style="border: 0.005rem solid rgba(226, 230, 235, 0.85); height: 50px"
						>
							<div>
								<div class="text-muted fs-14 w-100">{{ this.FormMSG(2, 'No data available') }}</div>
							</div>
						</b-td>
					</b-tr>
					<b-tr v-show="setAllTimesheets.length !== 0" v-for="(item, index) of setAllTimesheets" :key="index">
						<b-td class="sticky-column-body" style="border: 0.005rem solid rgba(226, 230, 235, 0.85)">
							<div class="d-flex flex-row justify-content-start align-items-center">
								<div class="d-flex flex-column justify-content-start p-1" style="width: 150px; height: 60px">
									<div class="font-weight-bold fs-14">{{ item.fullName }}</div>
									<div>{{ item.departmentName }}</div>
								</div>
								<div class="d-flex flex-column justify-content-start align-items-center pd-1">
									<div class="d-flex flex-row w-100 show-hover">
										<!-- <b-button size="sm" class="btn bg-transparent border-0" v-b-tooltip.hover.bottom.html="'Not submitted 09'">
											<div class="d-flex flex-row" style="gap: 5px">
												<component :is="getLucideIcon('Bell')" color="#06263E" :size="16" :stroke-width="2.25" />
												<b-badge variant="danger">9</b-badge>
											</div>
										</b-button> -->
									</div>
									<div class="d-flex flex-row w-100 show-hover">
										<b-button
											:disabled="checkNotSubmitted(item)"
											@click="sendAllForValidation(item)"
											size="sm"
											class="btn bg-transparent border-0"
											v-b-tooltip.hover.bottom.html="`${FormMSG(13, 'Send all for validation')}`"
										>
											<component :is="getLucideIcon(ICONS.SEND.name)" :color="ICONS.SEND.color" :size="16" :stroke-width="2.25" />
										</b-button>
									</div>
								</div>
							</div>
						</b-td>
						<b-td
							v-for="data of tsfields.slice(1)"
							:key="generateSecureId(`${data.key}`)"
							:class="getWorked(data.key, item.tsDays) ? 'init-background hover-not-empty' : 'edit-background hover-is-empty'"
							style="border: 0.005rem solid rgba(226, 230, 235, 0.85)"
							@contextmenu.prevent="getWorked(data.key, item.tsDays) && showContextMenu($event, item, getDataOnCell(data.key, item, item.id))"
							@click.stop.prevent="(e) => handleAllActionPlanning(e, item, data.key)"
							@mousedown="(e) => startDrag(e, item, data.key)"
							@mouseover="(e) => selectCell(e, item, data.key)"
							@mouseup="(e) => endDrag(e)"
							:id="generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + data.key}`)"
						>
							<div class="d-flex flex-column" style="width: 100px; height: 100%; gap: 2px">
								<div v-if="getWorked(data.key, item.tsDays)" class="d-flex position-relative">
									<div class="d-flex w-100 justify-content-end">
										<button
											class="btn-transparent text-color-rhapsody-in-blue"
											v-b-tooltip.hover.left.html="`${getLabelStatus(getStatus(data.key, item.tsDays))}`"
										>
											<div :class="`status-${getStatusColor(getStatus(data.key, item.tsDays))}`" style="font-size: 0.7rem"></div>
										</button>
									</div>
									<div class="d-flex position-absolute flex-row w-100 h-auto justify-center align-items-center flex-wrap mt-3 ml-3">
										<div class="d-flex justify-content-center flex-column">
											<button
												class="btn-transparent text-color-rhapsody-in-blue"
												v-b-tooltip.hover.left.html="tooltipContentOvertime(data.key, item.tsDays)"
											>
												<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" />
											</button>
										</div>
										<div>
											<div>{{ getWorked(data.key, item.tsDays) }}</div>
											<div>{{ getSalary(data.key, item.tsDays) }}</div>
										</div>
									</div>
								</div>
							</div>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<div v-if="isBusy" class="d-flex justify-content-center align-items-center" style="height: 20px; gap: 5px">
				<div class="loading">{{ this.FormMSG(9, 'Loading') }}</div>
				<div class="bars-5"></div>
			</div>
			<div v-else class="d-flex flex-row justify-content-center align-items-center" style="height: 20px">
				<div class="end-of-list">{{ this.FormMSG(10, 'End of list') }}</div>
			</div>

			<div id="ContextMenu" class="context-menu" v-if="showMenu" :style="{ top: menuY + 'px', left: menuX + 'px' }" ref="contextMenu">
				<div
					v-if="isShowActionByStatus(this.selectedItem)"
					@click="onMenuItemClick(1)"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.PENCIL.name)" :color="ICONS.PENCIL.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ this.FormMSG(7, 'Update') }}</div>
				</div>
				<div
					v-if="isNotValitedItem(this.selectedItem)"
					@click="onMenuItemClick(2)"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.SEND.name)" :color="ICONS.SEND.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ FormMSG(12, 'Send For Validation') }}</div>
				</div>
				<div
					v-if="isValidated(this.selectedItem)"
					@click="isValid ? onMenuItemClick(2.1) : isReject ? onMenuItemClick(2.2) : () => []"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component
							v-if="isValid"
							:is="getLucideIcon(ICONS.CHECK_SQUARE.name)"
							:color="ICONS.CHECK_SQUARE.color"
							:size="16"
							:stroke-width="2.25"
						/>
						<component v-if="isReject" :is="getLucideIcon(ICONS.X_CIRCLE.name)" :color="ICONS.X_CIRCLE.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ isValid ? `${FormMSG(14, 'Validate')}` : `${FormMSG(15, 'Reject')}` }}</div>
				</div>
				<div
					v-if="isUndoReject(this.selectedItem)"
					@click="onMenuItemClick(2.3)"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.UNDO_2.name)" :color="ICONS.UNDO_2.color" :size="16" :stroke-width="2.25"
					/></b-button>
					<div>{{ FormMSG(20, 'Undo reject') }}</div>
				</div>
				<div @click="onMenuItemClick(3)" class="d-flex justify-content-start align-items-center pd-1 menu-context">
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.EYE.name)" :color="ICONS.EYE.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ FormMSG(11, 'See info') }}</div>
				</div>
				<div
					v-if="isShowActionByStatus(this.selectedItem)"
					@click="onMenuItemClick(4)"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div class="d-flex">{{ this.FormMSG(6, 'Delete') }}</div>
				</div>
				<div @click="hideContextMenu()" class="d-flex justify-content-start align-items-center pd-1 menu-context">
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon('ShieldClose')" :color="ICONS.PENCIL.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ this.FormMSG(8, 'Cancel') }}</div>
				</div>
			</div>
		</b-col>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(16, 'Informations')"
			class="modal-success"
			v-model="showDetailModalCell"
			no-close-on-backdrop
			@ok="hideModal"
			ok-variant="success"
			ok-only
		>
			<div class="container-mobile animated fadeIn">
				<div class="content-detailsMyTimesheet-mobile">
					<show-detail-planning-cell :showDetailData="showDetailData" />
				</div>
			</div>
		</b-modal>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(17, 'Refuse this day')"
			class="modal-danger"
			v-model="successModalRejectedTsDay"
			no-close-on-backdrop
			ok-variant="danger"
			ok-only
		>
			<b-form-group :label="FormMSG(18, 'Enter your comment:')" label-for="comment" :label-cols="4">
				<b-form-textarea id="comment" v-model="rejectComment" :placeholder="this.FormMSG(19, 'Enter your remarks...')" rows="3"></b-form-textarea>
			</b-form-group>
			<template #modal-footer>
				<div class="w-100">
					<b-button @click="finaliseTsDayReject" variant="danger" size="sm" class="float-right"> OK </b-button>
				</div>
			</template>
		</b-modal>
	</b-row>
</template>
<script>
import MonthSelect from '@/components/EntryForCrew/Timesheets/TimesheetsEfcTables/MonthSelect';
import ShowDetailPlanningCell from '@/components/EntryForCrew/Timesheets/TimesheetsEfcTables/ShowDetailPlanningCell';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { getTimesheetsToValidate, getTimesheetsDetailAuthToValidate, rejectTsDay, undoRejectTsDay } from '@/cruds/timesheets-efc.crud';
import { FILTER_STRING_TIME_SHEET, NAME_CARDS_TIMESHEET_EFC, ACTION_EFC_TIMESHEET } from '@/utils/utils';
import { rendCurrency } from '~helpers';
import { generateSecureId } from '@/shared/utils';
import { classStatus } from '@/shared/global-status';
import moment from 'moment';

export default {
	name: 'PlanningViewTables',
	components: { MonthSelect, ShowDetailPlanningCell },
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		useComponent: {
			type: Boolean,
			required: false
		},
		id: {
			type: String,
			required: false
		},
		setFilter: {
			type: Function,
			required: false
		},
		setPages: {
			type: Function,
			required: false
		},
		groupByFilter: {
			type: String,
			required: false
		},
		onSearch: {
			type: Function,
			required: false
		},
		selectDate: {
			type: Array,
			required: false
		},
		formChanged: {
			type: Function,
			required: false
		},
		hover: {
			type: Boolean,
			required: false
		},
		timesheets: {
			type: Array,
			required: false
		},
		tsfields: {
			type: Array,
			required: false
		},
		currentpage: {
			type: Number,
			required: false
		},
		perpage: {
			type: Number,
			required: false
		},
		hv: {
			type: String,
			required: false
		},
		DateDefaultSelect: {
			type: Object,
			required: false,
			immediate: true
		},
		open: {
			type: Boolean,
			required: false
		},
		isPersistDataPlanning: {
			type: Boolean,
			required: false
		},
		isActionCrud: {
			type: String,
			required: false
		}
	},
	data: () => {
		const { groupByDate, groupByPerson, groupByStatus, planningView } = FILTER_STRING_TIME_SHEET;
		const { timecards_One, timesheets_Two } = NAME_CARDS_TIMESHEET_EFC;
		const { addData, updateData, deleteData } = ACTION_EFC_TIMESHEET;
		return {
			filter: '',
			currentForm: {
				value: 0,
				text: 'Select Month'
			},
			groupActionCell: '',
			groupByActionValue: null,
			groupByPerson,
			groupByDate,
			planningView,
			groupByStatus,
			addData,
			updateData,
			deleteData,
			selected: '',
			setPage: 0,
			timecards_One,
			timesheets_Two,
			reset: true,
			notReset: false,
			rendCurrency,
			isNoCtrolSelect: false,
			isKeyDate: '',
			dataActifs: [],
			dataActifsUpdate: [],
			generateSecureId,
			isBusy: false,
			showMenu: false,
			menuX: 0,
			menuY: 0,
			selectedItem: null,
			MyFunctionHideContextMenu: false,
			scrollPosition: 0,
			isActiveClickCell: 1,
			sendDataToModalPlanning: [],
			isDragging: false,
			scrollLeftStart: 0,
			startX: 0,
			selectedCells: [],
			stateGlobalData: [],
			indexActif: 0,
			dateSelectedCells: [],
			stateGlobalDates: [],
			startIndex: 0,
			endIndex: 0,
			itemPersist: [],
			datePersist: [],
			arraysId: [],
			dataArraysStores: [],
			ctrlDataPersist: [],
			dataActifsActions: [],
			activeStart: false,
			dragging: false,
			classStatus,
			dataToValidate: [],
			dataToValidate: [],
			isValid: false,
			isReject: false,
			showDetailModalCell: false,
			successModalRejectedTsDay: false,
			rejectComment: '',
			showDetailData: {},
			rejectData: []
		};
	},
	watch: {
		timesheets: {
			async handler(newVal, oldVal) {
				if (this.isBusy) {
					return newVal;
				} else {
					// setTimeout(() => {
					// 	for (let _x of this.dataActifs) {
					// 		if (document.getElementById(_x.securedId) !== null) {
					// 			const style = document.getElementById(_x.securedId).style;
					// 			document.getElementById(_x.securedId).style = style;
					// 			document.getElementById(_x.securedId).style.backgroundColor = '#00a09c';
					// 			document.getElementById(_x.securedId).style.color = '#fff';
					// 			if (document.getElementById(_x.securedId).querySelectorAll('svg > *')[0] !== undefined) {
					// 				document.getElementById(_x.securedId).querySelectorAll('svg > *')[0].style.fill = '#fff';
					// 			}
					// 			document.getElementById(_x.securedId).style.border = '0.005rem solid rgba(226, 230, 235, 0.85)';
					// 			document.getElementById(_x.securedId).style.height = '50px';
					// 		}
					// 	}
					// }, 300);
					return oldVal;
				}
			},
			immediate: true,
			deep: true
		},
		open: {
			handler(newVal) {
				if (newVal !== false) {
					if (this.isActionCrud === this.addData) {
						this.sendDataToModalPlanning = [...this.dataActifs, ...this.dataActifsUpdate];
						this.$emit('sendDataModalPlanning', this.sendDataToModalPlanning);
						console.log('data: ', this.sendDataToModalPlanning);
					}
				} else {
					if (this.isPersistDataPlanning) {
						this.sendDataToModalPlanning = [...this.dataActifs, ...this.dataActifsUpdate];
						this.$emit('sendDataModalPlanning', this.sendDataToModalPlanning);
					} else {
						this.dataActifs = [];
						this.dataActifsUpdate = [];
						this.sendDataToModalPlanning = [];
						this.$emit('sendDataModalPlanning', this.sendDataToModalPlanning);
						this.$emit('showModalPlanningContextUpdate', this.sendDataToModalPlanning);
					}
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		dataActifs: {
			handler(newVal) {
				let data = newVal;
				this.$emit('setDataSizeModalPlanning', data);
				return newVal;
			},
			immediate: true,
			deep: true
		},
		showMenu: {
			handler(newVal) {
				if (newVal === false) {
					this.menuX = 0;
					this.menuY = 0;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	created() {
		this.GetTimesheetsToValidate();
	},
	mounted() {
		//init table scroll
		if (this.$refs['my-table'] !== undefined) {
			const tableScrollBody = this.$refs['my-table'].$el;
			tableScrollBody.addEventListener('mousemove', this.detectDirection);
			tableScrollBody.addEventListener('mouseup', this.stopDrag);
			tableScrollBody.addEventListener('scroll', this.onScroll);
		}
	},
	beforeDestroy() {
		if (this.$refs['my-table'] !== undefined) {
			const tableScrollBody = this.$refs['my-table'].$el;
			tableScrollBody.removeEventListener('scroll', this.onScroll);
		}
	},
	computed: {
		setDataTableFields() {
			return this.dataTable;
		},
		setAllTimesheets() {
			if (this.$refs['my-table'] !== undefined) {
				const tableScrollBody = this.$refs['my-table'].$el;
				tableScrollBody.addEventListener('scroll', this.onScroll);
			}
			return this.timesheets;
		}
	},
	methods: {
		async GetTimesheetsToValidate() {
			let data = [...(await getTimesheetsToValidate())].map((item) => +item.id);
			let arraysIdToValidate = [];
			for (let i = 0; i < data.length; i++) {
				let id = +data[i];
				let result = await await getTimesheetsDetailAuthToValidate(id);
				arraysIdToValidate.push(...result);
			}
			this.dataToValidate = arraysIdToValidate;
		},
		async finaliseTsDayReject() {
			let TsDayId = +this.rejectData[0].id;
			let Comment = this.rejectComment;
			this.successModalRejectedTsDay = true;
			if (Comment.length !== 0) {
				let result = await rejectTsDay(TsDayId, Comment)
					.then(async (result) => {
						if (result) {
							this.successModalRejectedTsDay = false;
							this.$emit('handleRefreshReject');
							this.GetTimesheetsToValidate();
							this.rejectComment = '';
							this.rejectData = [];
						}
					})
					.catch((error) => {
						throw new error();
					});
				return result;
			} else {
				this.successModalRejectedTsDay = true;
			}
		},
		getStatus(key, arrays) {
			let _data = arrays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					if (moment(item.date).format('DD/MM/YYYY') === key) {
						return true;
					}
					return false;
				});
				if (result.length !== 0) {
					return result[0].validated;
				}
			}
		},
		getStatusColor(validated) {
			let retval = 'not-submitted';
			if (validated == 1) {
				retval = 'info';
			} else if (validated == 2) {
				retval = 'pending';
			} else if (validated == 8) {
				retval = 'validated';
			} else if (validated == 4 || validated == 16) {
				retval = 'refused';
			}
			return retval;
		},
		getLabelStatus(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, value);
		},
		getUniqueCombination(data) {
			let uniqueCombinations = new Set();
			let uniqueData = data.filter((item) => {
				let combination = `${item.date}-${item.userId}`;
				if (!uniqueCombinations.has(combination)) {
					uniqueCombinations.add(combination);
					return true;
				}
				return false;
			});
			return uniqueData;
		},
		getWorked(key, arrays) {
			let _data = arrays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					if (moment(item.date).format('DD/MM/YYYY') === key) {
						return true;
					}
					return false;
				});
				if (result.length !== 0) {
					let hours = result[0].hours.toString().padStart('2', '0');
					let minutes = result[0].minutes.toString().padStart('2', '0');
					return `${hours}:${minutes}`;
				}
			}
		},
		getDataOnCell(key, arrays, userId) {
			let _data = arrays.tsDays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					if (moment(item.date).format('DD/MM/YYYY') === key) {
						return true;
					}
					return false;
				});
				return [{ ...arrays, tsDays: [{ ...result[0], userId }] }];
			}
		},
		getSalary(key, arrays) {
			let _data = arrays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					if (moment(item.date).format('DD/MM/YYYY') === key) {
						return true;
					}
					return false;
				});
				if (result.length !== 0) {
					return `${rendCurrency(result[0].salary)}`;
				}
			}
		},
		tooltipContentOvertime(key, arrays) {
			let hours = this.getWorked(key, arrays);
			let salary = this.getSalary(key, arrays);
			return `${this.FormMSG(3, 'Total worked: ')} ${hours}<br />${this.FormMSG(4, 'Salary: ')} ${salary}`;
		},
		setDataActionAnim(data) {
			if (data !== undefined) {
				for (let i = 0; i < data.length; i++) {
					const style = document.getElementById(data[i].securedId).style;
					document.getElementById(data[i].securedId).style = style;
					document.getElementById(data[i].securedId).style.backgroundColor = '#00a09c';
					document.getElementById(data[i].securedId).style.color = '#fff';
					document.getElementById(data[i].securedId).style.border = '0.005rem solid rgba(226, 230, 235, 0.85)';
					document.getElementById(data[i].securedId).style.height = '50px';
				}

				this.dataActifs = this.getUniqueCombination([...this.dataActifs, ...data]);
			}
		},
		setDataActionAnimInit(data) {
			if (data !== undefined) {
				for (let i = 0; i < data.length; i++) {
					const style = document.getElementById(data[i].securedId).style;
					document.getElementById(data[i].securedId).style = style;
					document.getElementById(data[i].securedId).style.border = '0.005rem solid rgba(226, 230, 235, 0.85)';
					document.getElementById(data[i].securedId).style.height = '50px';
				}
			}
		},
		resetClass(id) {
			const style = document.getElementById(id).style;
			document.getElementById(id).style = style;
			document.getElementById(id).style.border = '0.005rem solid rgba(226, 230, 235, 0.85)';
			document.getElementById(id).style.height = '50px';
		},
		activeClass(id) {
			const style = document.getElementById(id).style;
			document.getElementById(id).style = style;
			document.getElementById(id).style.backgroundColor = '#00a09c';
			document.getElementById(id).style.color = '#fff';
			if (document.getElementById(id).querySelectorAll('svg > *')[0] !== undefined) {
				document.getElementById(id).querySelectorAll('svg > *')[0].style.fill = '#fff';
			}
			document.getElementById(id).style.border = '0.005rem solid rgba(226, 230, 235, 0.85)';
			document.getElementById(id).style.height = '50px';
		},
		startDrag(event, item, key) {
			if (event.button === 0) {
				this.dragging = true;
			}
		},
		selectCell(event, item, key) {
			if (event.button === 0) {
				if (this.dragging) {
					this.handleShiftWithClick(event, item, key);
				}
			}
		},
		endDrag(event) {
			if (event.button === 0) {
				this.dragging = false;
			}
		},
		handleCtrlClick(event, item, key) {
			let id = generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + key}`);
			this.dataActifsActions = this.getUniqueCombination([...this.dataActifsActions, ...this.dataActifs]);
			if (this.dataActifs.length === 0) {
				this.activeClass(id);
				this.dataActifs = [...this.dataActifs, { securedId: id, userId: +item.id, fullName: item.fullName, date: key, dataAdd: item }];
			} else {
				let isFind = this.dataActifs.find((e) => e.securedId === id);

				if (isFind !== undefined) {
					this.resetClass(id);
					// data actifs max arrays number 2
					this.dataActifs = this.dataActifs.filter((item) => item.securedId !== id);
					this.dateSelectedCells = this.dateSelectedCells.filter((date) => date !== key);
					// data actifs actions no max number of arrays
					this.dataActifsActions = this.dataActifsActions.filter((item) => item.securedId !== id);
					this.dataArraysStores = this.dataActifs;
				} else {
					this.activeClass(id);
					this.setDataActionAnim(this.dataActifsActions);
					if (this.selectedCells.length !== 0) {
						this.stateGlobalData = [...this.stateGlobalData, this.selectedCells];
						this.stateGlobalDates = [...this.stateGlobalDates, this.dateSelectedCells];
					}
					this.indexActif = 1;
					this.dataActifs = [
						...this.dataActifs,
						{
							securedId: id,
							userId: +item.id,
							fullName: item.fullName,
							date: key,
							dataAdd: item
						}
					];
					this.selectedCells = [
						{
							securedId: id,
							userId: +item.id,
							fullName: item.fullName,
							index: this.indexActif,
							date: key,
							indexOfItem: [...this.setAllTimesheets].indexOf(item),
							dataAdd: item
						}
					];
				}
			}
		},
		handleClickOnly(event, item, key) {
			let id = generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + key}`);
			let dataActifs = this.dataActifs;
			let dataActifsUpdate = this.dataActifsUpdate;
			this.indexActif = 0;
			this.selectedCells = [];
			this.setDataActionAnimInit(this.stateGlobalData[0]);
			this.stateGlobalData = [];
			this.dataActifsActions = [];
			if (this.itemPersist[this.itemPersist.length - 1] !== undefined) {
				for (let i = 0; i < this.itemPersist[this.itemPersist.length - 1].length; i++) {
					for (let j = 0; j < this.dateSelectedCells.length; j++) {
						let key = this.dateSelectedCells[j];
						let id = generateSecureId(
							`${
								this.itemPersist[this.itemPersist.length - 1][i].id +
								this.itemPersist[this.itemPersist.length - 1][i].fullName +
								this.itemPersist[this.itemPersist.length - 1][i].departmentName +
								this.itemPersist[this.itemPersist.length - 1][i].functionName +
								key
							}`
						);
						if (document.getElementById(id) !== null) {
							this.resetClass(id);
						}
					}
				}
			}
			if (this.dataActifs.length !== 0 && this.dataActifs[0].securedId === id) {
				this.resetClass(id);
				this.dataActifs = [];
				this.dataActifsUpdate = [];
				this.handleSendDataPlanningToModal();
			} else {
				let indexOfItem = [...this.setAllTimesheets].indexOf(item);
				dataActifs.forEach((item) => {
					if (document.getElementById(item.securedId) !== null) {
						this.resetClass(item.securedId);
					}
				});
				dataActifsUpdate.forEach((item) => {
					if (document.getElementById(item.securedId) !== null) {
						document.getElementById(item.securedId).style.backgroundColor = '#ffff';
					}
				});
				this.arraysId.forEach((id) => {
					if (document.getElementById(id) !== null) {
						this.resetClass(id);
					}
				});
				this.dataActifs = [];
				this.dataActifsUpdate = [];
				this.arraysId = [];
				this.dataArraysStores = [];
				this.selectedCells = [];
				this.dateSelectedCells = [];
				this.stateGlobalData = [];
				this.stateGlobalDates = [];
				this.handleSendDataPlanningToModal();
				const color = document.getElementById(id).style.color;
				document.getElementById(id).style.backgroundColor = '#00a09c';
				!this.getWorked(key, item.tsDays)
					? (document.getElementById(id).style.color = color)
					: ((document.getElementById(id).style.color = '#ffff'),
					  (document.getElementById(id).style.fontWeight = 'bold'),
					  document.getElementById(id).querySelectorAll('svg > *')[0] !== undefined &&
							(document.getElementById(id).querySelectorAll('svg > *')[0].style.fill = '#fff'));
				this.activeStart = true;
				this.dataActifs = [{ securedId: id, userId: +item.id, fullName: item.fullName, index: 1, indexOfItem: indexOfItem, date: key, dataAdd: item }];
				this.ctrlDataPersist = this.dataActifs;
			}
		},
		handleShiftWithClick(event, item, key) {
			let id = generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + key}`);
			this.indexActif += 1;
			let indexOfItem = [...this.setAllTimesheets].indexOf(item);

			if (this.indexActif > 1) {
				this.indexActif = 2;
				let id = generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + key}`);
				this.selectedCells = [
					this.selectedCells[0],
					{ securedId: id, userId: +item.id, fullName: item.fullName, index: this.indexActif, date: key, indexOfItem: indexOfItem, dataAdd: item }
				];
				this.dataActifs = this.getUniqueCombination([...this.dataActifs, ...this.selectedCells]);
				this.setDataActionAnim(this.dataActifsActions);
			} else {
				if (this.activeStart && this.dataActifs.length !== 0) {
					this.indexActif = 2;
					let id = generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + key}`);
					let lastId = generateSecureId(
						`${
							this.dataActifs[0].dataAdd.id +
							this.dataActifs[0].dataAdd.fullName +
							this.dataActifs[0].dataAdd.departmentName +
							this.dataActifs[0].dataAdd.functionName +
							this.dataActifs[0].date
						}`
					);
					this.selectedCells = [
						{
							securedId: lastId,
							userId: +this.dataActifs[0].dataAdd.id,
							fullName: this.dataActifs[0].fullName,
							index: 1,
							date: this.dataActifs[0].date,
							indexOfItem: [...this.setAllTimesheets].indexOf(this.dataActifs[0].dataAdd),
							dataAdd: this.dataActifs[0].dataAdd
						},
						{ securedId: id, userId: +item.id, fullName: item.fullName, index: this.indexActif, date: key, indexOfItem: indexOfItem, dataAdd: item }
					];
					this.activeStart = false;
				} else {
					let id = generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + key}`);
					this.selectedCells = [
						{ securedId: id, userId: +item.id, fullName: item.fullName, index: this.indexActif, date: key, indexOfItem: indexOfItem, dataAdd: item }
					];
					this.itemPersist = [...this.itemPersist, [item]];
					this.dataActifs = this.getUniqueCombination([...this.dataActifs, ...this.selectedCells]);
				}
			}

			let data = [...this.setAllTimesheets];
			if (this.selectedCells.length < 2) {
				let item = data.slice(this.selectedCells[0].indexOfItem, this.selectedCells[0].indexOfItem + 1);
				let id = generateSecureId(`${item[0].id + item[0].fullName + item[0].departmentName + item[0].functionName + key}`);
				this.activeClass(id);
			} else {
				let item = [];
				if (this.selectedCells[0].indexOfItem < this.selectedCells[1].indexOfItem + 1) {
					item = data.slice(this.selectedCells[0].indexOfItem, this.selectedCells[1].indexOfItem + 1);
				} else {
					item = data.slice(this.selectedCells[1].indexOfItem, this.selectedCells[0].indexOfItem + 1);
				}
				this.itemPersist = [...this.itemPersist, item];
				if (this.itemPersist[this.itemPersist.length - 2] !== undefined) {
					for (let i = 0; i < this.itemPersist[this.itemPersist.length - 2].length; i++) {
						for (let j = 0; j < this.dateSelectedCells.length; j++) {
							let key = this.dateSelectedCells[j];
							let id = generateSecureId(
								`${
									this.itemPersist[this.itemPersist.length - 2][i].id +
									this.itemPersist[this.itemPersist.length - 2][i].fullName +
									this.itemPersist[this.itemPersist.length - 2][i].departmentName +
									this.itemPersist[this.itemPersist.length - 2][i].functionName +
									key
								}`
							);
							if (document.getElementById(id) !== null) {
								this.resetClass(id);
							}
						}
					}
					setTimeout(() => {
						this.setDataActionAnim(this.dataActifsActions);
					}, 0);
				}
				this.setDataActionAnim(this.dataActifsActions);
				let dayStart = +this.selectedCells[0].date.split`/`[0];
				let dayEnd = +this.selectedCells[1].date.split`/`[0];
				let monthYear = this.selectedCells[0].date.substr(2);
				// vierifié les autres event avant le click persist et initialisé la valeur
				if (this.dateSelectedCells.length !== 0) {
					for (let i = 0; i < item.length; i++) {
						for (let j = 0; j < this.dateSelectedCells.length; j++) {
							let key = this.dateSelectedCells[j];
							let id = generateSecureId(`${item[i].id + item[i].fullName + item[i].departmentName + item[i].functionName + key}`);
							if (document.getElementById(id) !== null) {
								this.resetClass(id);
							}
						}
					}
				}
				//change color primary quand on click sur ctrl and que les données ne sont pas encore vide suivi du shift
				for (let i = 0; i < this.arraysId.length; i++) {
					let id = this.arraysId[i];
					this.activeClass(id);
				}
				this.dateSelectedCells = [];
				if (dayStart < dayEnd) {
					while (dayStart <= dayEnd) {
						this.dateSelectedCells.push(dayStart.toString().padStart('2', '0') + monthYear);
						dayStart++;
					}
				} else {
					while (dayStart >= dayEnd) {
						this.dateSelectedCells.push(dayEnd.toString().padStart('2', '0') + monthYear);
						dayEnd++;
					}
				}
				for (let i = 0; i < item.length; i++) {
					for (let j = 0; j < this.dateSelectedCells.length; j++) {
						let key = this.dateSelectedCells[j];
						let id = generateSecureId(`${item[i].id + item[i].fullName + item[i].departmentName + item[i].functionName + key}`);
						const color = document.getElementById(id).style.color;
						document.getElementById(id) !== null && (document.getElementById(id).style.backgroundColor = '#00a09c');
						!this.getWorked(key, item[i].tsDays)
							? (document.getElementById(id).style.color = color)
							: ((document.getElementById(id).style.color = '#ffff'),
							  (document.getElementById(id).style.fontWeight = 'bold'),
							  document.getElementById(id).querySelectorAll('svg > *')[0] !== undefined &&
									(document.getElementById(id).querySelectorAll('svg > *')[0].style.fill = '#fff'));
					}
				}
			}

			let allUser = [...new Set(this.itemPersist[this.itemPersist.length - 1])];
			let dateOnly = [...new Set(this.dateSelectedCells)];
			let arrays = [];
			for (let i = 0; i < allUser.length; i++) {
				for (let j = 0; j < dateOnly.length; j++) {
					let id = generateSecureId(`${allUser[i].id + allUser[i].fullName + allUser[i].departmentName + allUser[i].functionName + dateOnly[j]}`);
					arrays.push({
						securedId: id,
						userId: +allUser[i].id,
						fullName: allUser[i].fullName,
						date: dateOnly[j],
						dataAdd: item
					});
				}
			}
			this.dataActifs = this.getUniqueCombination([...this.ctrlDataPersist, ...this.dataArraysStores, ...arrays]);
		},
		handleAllActionPlanning(event, item, key) {
			if (this.showMenu) {
				this.showMenu = false;
			}
			setTimeout(() => {
				if (event.ctrlKey) {
					this.handleCtrlClick(event, item, key);
				} else if (event.shiftKey) {
					this.handleShiftWithClick(event, item, key);
				} else {
					this.handleClickOnly(event, item, key);
				}
			}, 1);
		},
		handleSendDataPlanningToModal() {
			let data = [...this.dataActifs, ...this.dataActifsUpdate];
			this.$emit('setDataSizeModalPlanning', data);
		},
		setDateDefaultSelect(_date) {
			this.$emit('changeDateDefaultSelect', _date);
			this.dataActifs = [];
			this.selectedCells = [];
			this.indexActif = 0;
			this.handleSendDataPlanningToModal();
		},
		showContextMenu(event, item, worked) {
			//context menu change position
			this.showMenu = false;
			this.showMenu = true;
			let screenWidth = window.innerWidth;
			let screenHeight = window.innerHeight;
			event.preventDefault();
			const menuWidth = 200;
			const menuHeight = 150;
			const clickX = event.clientX;
			const clickY = event.clientY;
			const maxLeft = screenWidth - menuWidth;
			this.menuY = event.clientY;
			const maxTop = screenHeight - menuHeight;
			if (clickX > maxLeft) {
				this.menuX = Math.max(clickX - menuWidth, 0);
			} else {
				this.menuX = clickX;
			}
			if (clickY > maxTop) {
				this.menuY = Math.max(clickY - menuHeight, 0);
			} else {
				this.menuY = clickY;
			}
			this.showMenu = true;
			this.selectedItem = worked;
		},
		hideContextMenu() {
			this.showMenu = false;
			this.selectedItem = null;
		},
		isNotValitedItem(item) {
			if (item !== null) {
				let data = item[0].tsDays[0].validated;
				if (data === 0) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		},
		isShowActionByStatus(item) {
			if (item !== null) {
				let validated = +item[0].tsDays[0].validated;
				if (validated === 1 || validated === 2 || validated === 8) {
					return false;
				}
				return true;
			}
		},
		isValidated(item) {
			if (item !== null) {
				let id = +item[0].tsDays[0].id;
				let isFound = this.dataToValidate.find((item) => +item.id === +id);
				if (isFound !== undefined) {
					if (item[0].tsDays[0].validated === 2) {
						this.isReject = true;
						this.isValid = false;
						return true;
					}
					if (item[0].tsDays[0].validated === 1) {
						this.isReject = false;
						this.isValid = true;
						return true;
					}
					this.isValid = false;
					this.isReject = false;
					return false;
				}
				return false;
			}
		},
		isUndoReject(item) {
			if (item !== null) {
				let validated = item[0].tsDays[0].validated;
				if (validated === 4) {
					return true;
				}
				return false;
			}
		},
		isCheckValidate(key, item, id) {
			let data = this.dataToValidate;
			let cellData = this.getDataOnCell(key, item, id);
			let tsDayId = cellData[0].tsDays[0].id;
			if (data.length !== 0) {
				let result = data.find((item) => +item.id === +tsDayId);
				if (result !== undefined) {
					return true;
				}
				return false;
			}
		},
		async onMenuItemClick(option) {
			if (option === 1) {
				let item = this.selectedItem[0];
				let id = generateSecureId(
					`${item.tsDays[0].id + item.tsDays[0].fullName + item.tsDays[0].departmentName + item.tsDays[0].functionName + item.tsDays[0].date}`
				);
				let date = moment(item.tsDays[0].date).format('DD/MM/YYYY');
				let data = [{ securedId: id, userId: +item.tsDays[0].userId, fullName: item.fullName, date: date, data: item }];
				this.dataActifsUpdate = data;
				this.$emit('showModalPlanningContextUpdate', data);
				this.hideContextMenu();
			}
			if (option === 2) {
				let item = this.selectedItem[0].tsDays;
				this.$emit('sendForValidationPlanning', item);
				this.hideContextMenu();
			}
			if (option === 2.1) {
				let item = this.selectedItem[0].tsDays;
				this.$emit('validateTsDays', item);
				this.hideContextMenu();
				this.GetTimesheetsToValidate();
			}
			if (option === 2.2) {
				this.successModalRejectedTsDay = true;
				this.rejectData = this.selectedItem[0].tsDays;
				this.hideContextMenu();
			}
			if (option === 2.3) {
				let TsDayId = +this.selectedItem[0].tsDays[0].id;
				let result = await undoRejectTsDay(TsDayId)
					.then(async (result) => {
						if (result) {
							this.$emit('handleRefreshReject');
							this.GetTimesheetsToValidate();
						}
					})
					.catch((error) => {
						throw new error();
					});
				this.hideContextMenu();
				return result;
			}
			if (option === 3) {
				let item = this.selectedItem[0].tsDays;
				this.showDetailData = { ...item[0], fullName: this.selectedItem[0].fullName };
				this.showDetailModalCell = true;
			}
			if (option === 4) {
				this.$emit('deleteItemPlanning', this.selectedItem);
				this.hideContextMenu();
			}

			this.showMenu = false;
			this.dataActifsUpdate = [];
			this.dataActifs = [];
		},
		hideModal() {
			this.showDetailModalCell = false;
			this.showDetailData = {};
		},
		sendAllForValidation(item) {
			let data = item.tsDays.filter((item) => item.validated === 0);
			this.$emit('sendForValidationPlanning', data);
			this.hideContextMenu();
		},
		checkNotSubmitted(item) {
			let data = item.tsDays.filter((item) => item.validated === 0);
			if (data.length === 0) {
				return true;
			} else {
				return false;
			}
		},
		onScroll(event) {
			if (event.target.scrollTop) {
				if (Math.ceil(event.target.scrollTop + event.target.clientHeight) >= event.target.scrollHeight) {
					if (!this.isBusy) {
						if (event.target.scrollLeft) {
							this.isBusy = false;
						} else {
							this.isBusy = true;
						}
						let recordOffSet = 1;
						setTimeout(() => {
							this.$emit('getDataFromPagePlanning', recordOffSet);
							this.isBusy = false;
						}, 1200);
					}
				}
			}
		},
		startScrollingLeft(event) {
			event.preventDefault();
			const scrollContainer = this.$refs['my-table'].$el;
			this.isDragging = true;
			this.startX = event.clientX;
			this.scrollLeftStart = scrollContainer.scrollLeft;
		},
		stopScrollingLeft(event) {
			event.preventDefault();
			const scrollContainer = this.$refs['my-table'].$el;
			scrollContainer.removeEventListener('mousedown', this.startScrollingLeft);
		},
		detectDirection(event) {
			event.preventDefault();
			if (this.isDragging) {
				const mouseX = event.clientX;
				const scrollContainer = this.$refs['my-table'].$el;
				if (mouseX > this.startX) {
					scrollContainer.scrollLeft = this.scrollLeftStart + (mouseX - this.startX);
				} else if (mouseX < this.startX) {
					scrollContainer.scrollLeft = this.scrollLeftStart - (this.startX - mouseX);
				}
			}
		},
		stopDrag() {
			this.isDragging = false;
		}
	}
};
</script>
<style scoped>
.middle {
	vertical-align: middle;
}
.show-hover:hover {
	background-color: #cad0d7;
	border-radius: 5px;
	cursor: pointer;
}
.edit-background {
	/* vertical-align: middle; */
	background-color: #f3f4f5;
}
.init-background {
	/*vertical-align: middle;*/
	background-color: #ffff;
}
.sticky-column-body {
	position: sticky;
	left: 0;
	z-index: 1;
	background-color: #fff;
}
.hover-not-empty:hover {
	background-color: hsla(206, 96%, 90%, 0.5);
}
.hover-is-empty:hover {
	background-color: rgba(71, 199, 191, 0.5);
}
.context-menu {
	position: fixed;
	z-index: 2;
	background-color: #fff;
	border: 1px solid #ccc;
	padding: 8px;
	border-radius: 10px;
}
.context-menu div {
	cursor: pointer;
	padding: 8px;
}
.menu-context {
	height: 30px;
}
.menu-context:hover {
	background-color: rgba(71, 199, 191, 0.5);
	border-radius: 10px;
}

@keyframes c4 {
	to {
		clip-path: inset(0 -1ch 0 0);
	}
}
.loading,
.end-of-list {
	color: rgba(6, 38, 62, 0.84);
	font-weight: 900;
	font-size: 0.95rem;
}
.bars-5 {
	width: 20px;
	height: 10px;
	aspect-ratio: 0.75;
	--c: no-repeat linear-gradient(rgba(6, 38, 62, 0.84) 0 0);
	background: var(--c) 0% 100%, var(--c) 50% 100%, var(--c) 100% 100%;
	background-size: 20% 65%;
	animation: b5 1s infinite linear;
}
@keyframes b5 {
	20% {
		background-position: 0% 50%, 50% 100%, 100% 100%;
	}
	40% {
		background-position: 0% 0%, 50% 50%, 100% 100%;
	}
	60% {
		background-position: 0% 100%, 50% 0%, 100% 50%;
	}
	80% {
		background-position: 0% 100%, 50% 100%, 100% 0%;
	}
}
.overflow-hidden {
	overflow: hidden;
}
::-webkit-scrollbar {
	width: 5px;
	background: #eee;
}
::-webkit-scrollbar:horizontal {
	height: 10px;
}
::-webkit-scrollbar-button {
	width: 5px;
	height: 5px;
	background: #666;
}
::-webkit-scrollbar-piece {
	background: #eee;
}
::-webkit-scrollbar-piece:start {
	background: #eee;
}
::-webkit-scrollbar-thumb {
	background: #333;
}
::-webkit-scrollbar-corner {
	background: #333;
}
@keyframes sonar {
	0% {
		transform: scale(0.6);
		opacity: 1;
	}
	100% {
		transform: scale(1.2);
		opacity: 0;
	}
}
@keyframes pulse {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(0.9);
	}
	50% {
		transform: scale(1);
	}
	80% {
		transform: scale(0.6);
	}
	100% {
		transform: scale(1);
	}
}
.status-not-submitted {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #fcede9;
	border: 1px solid #ea4e2c;
	animation: pulse 1.5s infinite;
}
.status-not-submitted:after {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #ea4e2c;
	animation: sonar 1.5s infinite;
}
.status-info {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #e7f2ff;
	border: 1px solid #225cbd;
}
.status-pending {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #fbf5e5;
	border: 1px solid #cd9a16;
}
.status-validated {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #ecf6e8;
	border: 1px solid #3c7442;
}
.status-refused {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #ffb0b0;
	border: 1px solid #980000;
}
.status-sent {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #d5d6d7;
	border: 1px solid #687887;
}
</style>
