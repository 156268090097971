<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}
</style>

<template>
	<errorContainer :error="error" :warning="warning">
		<custom-loading-planning :loading="isLoading" :label="this.FormMSG(40, 'Prepare data of timesheet entry for crew')" />
		<transition name="slide">
			<div class="form">
				<b-row v-if="Timesheets" class="animated fadeIn">
					<b-col cols="12" xl="12">
						<div class="clearfix mb-2">
							<b-row>
								<b-col>
									<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
										{{ FormMSG(77, 'Timesheets - Entry for crew') }}
									</h1>
								</b-col>
								<b-col md="auto" v-if="groupActionCellTimeCards !== planningView">
									<b-button @click="showModal()" class="btn btn-success pull-right block-sm">
										{{ FormMSG(3, 'Add to timesheet') }}
									</b-button>
								</b-col>
							</b-row>
							<b-row>
								<time-card-for-crew-modal
									:editData="editData"
									@add:Submitted="handleRefreshData"
									:isOpen="Open"
									:showModal="showModal"
									:hideModal="hideModal"
									:isActionCrud="isActionCrud"
									:addDataFunc="addDataFunc"
									:external-dates="planningToSend"
									@closeModal="closeModal"
									:screenUsedTheForm="`entry-for-crew`"
									:useFormInModal="useFormInModal"
								/>
							</b-row>
						</div>
						<b-row>
							<b-col class="hide-on-tablet" cols="12" xl="12">
								<b-card no-body>
									<b-tabs card v-model="currentExpenseTab">
										<b-tab :title="this.FormMSG(79, 'Time cards')" @click="setTabAction(timecards_One)">
											<b-card-text>
												<header-timesheets
													@month:Selected="handleSelectedFormFilter"
													:id="timecards_One"
													:set-filter="(item) => setFilter(item)"
													:filter="filter"
													@day:Selected="handleSelectedDay"
													:onSearch="onSearch"
													:selectDate="allDataTimeCards"
													:formChanged="(date, idTable) => formChanged(date, idTable)"
													:formChangedPlanningView="(date, idTable) => formChangedPlanningView(date, idTable)"
													:groupByFilter="groupActionCellTimeCards"
													@handleSelectDepartementChange="handleSelectDepartementChange"
													:dataPlanningSelect="dataPlanningSelect"
													:show-modal="showModal"
													:isNavigateInitDate="isNavigateInitDate"
													@setIsNavigateInitDate="setIsNavigateInitDate"
													@resetDataAfterAddUpdate="resetDataAfterAddUpdate"
													@refersh-on-delete-not-submitted-all="handleRefreshData"
													:dataGlobal="TimeCards"
												/>
												<div v-if="miniSpinnerLoadingTimeCards" class="clearfix mb-0">
													<div class="text-center">
														<div>
															<b-spinner variant="primary" label="Text Centered"></b-spinner>
														</div>
														<div>{{ this.FormMSG(78, 'Prepare All data') }}</div>
													</div>
												</div>
												<div v-else>
													<div v-if="groupActionCellTimeCards === planningView">
														<planning-view-tables
															:id="timecards_One"
															:selectDate="allDataTimesheets"
															:hover="hover"
															:currentpage="currentPage"
															:perpage="perPage"
															:hv="hv"
															:tsfields="fieldsTimeCardsPlanning"
															:timesheets="TimeCards"
															@setDataSizeModalPlanning="setDataSizeModalPlanning"
															@sendDataModalPlanning="sendDataModalPlanning"
															@showModalPlanningContextUpdate="showModalPlanningContextUpdate"
															:DateDefaultSelect="DateDefaultSelect"
															@changeDateDefaultSelect="changeDateDefaultSelect"
															@getDataFromPagePlanning="getDataFromPagePlanning"
															@validateTsDays="validateTsDays"
															@deleteItemPlanning="deleteItemPlanning"
															:open="Open"
															:isPersistDataPlanning="isPersistDataPlanning"
															@sendForValidationPlanning="sendForValidationPlanning"
															@handleRefreshReject="handleRefreshReject"
														/>
														<b-modal
															header-class="header-class-modal-doc-package"
															:title="FormMSG(17, 'Success')"
															class="modal-success"
															v-model="successModal"
															@ok="successModal = false"
															ok-variant="success"
															ok-only
														>
															{{ FormMSG(18, 'The timesheet has been validated!') }}
														</b-modal>
													</div>
													<div v-else-if="groupActionCellTimeCards === groupByDate">
														<timesheets-per-date-view
															:id="timecards_One"
															:groupByFilter="groupActionCellTimeCards"
															:hover="hover"
															:currentpage="currentPage"
															:perpage="perPage"
															:hv="hv"
															@refreshRequested="handleRefreshData"
															:timeCards="TimeCards"
															:tsfields="fieldsTimeCards"
															:deleteAction="(item) => deleteAction(item, timecards_One)"
															@changePageSelectLimit="changePageSelectLimit"
															@handleNextPerDate="handleNextPerDate"
															@handlePreviousPerDate="handlePreviousPerDate"
															@refreshDataItemPerDate="refreshDataItemPerDate"
															:totalAllPageRecords="totalAllPageRecords"
															:isActionCrud="isActionCrud"
														/>
													</div>
													<div v-else>
														<div
															v-if="TimeCards.length === 0"
															class="d-flex flex-row justify-content-center align-items-center border"
															style="height: 40px"
														>
															{{ this.FormMSG(91, 'No data') }}
														</div>
														<div v-else>
															<div
																v-for="item in TimeCards"
																:key="generateSecureId(`${item.id + Math.random()}`)"
																class="animated fadeIn"
															>
																<timesheets-efc-tables
																	:id="timecards_One"
																	:hover="hover"
																	:timesheets="item.data"
																	:tsfields="fieldsTimeCards"
																	:currentpage="currentPage"
																	:perpage="perPage"
																	:hv="hv"
																	:rowclicked="(item) => rowClicked(item)"
																	:i="generateSecureId(item.id + item.date + item.fullName + item.departmentName)"
																	:groupByFilter="groupActionCellTimeCards"
																	:deleteAction="(item, idTable) => deleteAction(item, idTable)"
																	:hideModal="hideModal"
																	@add:Submitted="handleRefreshData"
																/>
															</div>
														</div>
													</div>
												</div>
											</b-card-text>
										</b-tab>
										<b-tab :title="this.FormMSG(80, 'Timesheets')" @click="setTabAction(timesheets_Two)">
											<b-card-text>
												<header-timesheets
													@month:Selected="handleSelectedFormFilter"
													:id="timesheets_Two"
													:set-filter="(item) => setFilter(item)"
													:useComponent="true"
													:filter="filter"
													:selectDate="allDataTimesheets"
													:onSearch="onSearch"
													:formChanged="(date, idTable) => formChanged(date, idTable)"
													:groupByFilter="groupActionCellTimesheets"
													:isNavigateInitDate="isNavigateInitDate"
													@setIsNavigateInitDate="setIsNavigateInitDate"
												/>
												<div v-if="miniSpinnerLoadingTimeSheet" class="clearfix mb-0">
													<div class="text-center">
														<div>
															<b-spinner variant="primary" label="Text Centered"></b-spinner>
														</div>
														<div>{{ this.FormMSG(78, 'Prepare All data') }}</div>
													</div>
												</div>
												<div v-else>
													<div
														v-if="Timesheets.length === 0"
														class="d-flex flex-row justify-content-center align-items-center border"
														style="height: 40px"
													>
														{{ this.FormMSG(91, 'No data') }}
													</div>
													<div v-else>
														<div
															v-for="item in Timesheets"
															:key="generateSecureId(`${item.id + Math.random()}`)"
															class="animated fadeIn"
														>
															<timesheets-efc-tables
																:id="timesheets_Two"
																:isTimesheet="true"
																:hover="hover"
																:timesheets="item.data"
																:tsfields="fieldsTimesheets"
																:currentpage="currentPage"
																:perpage="perPage"
																:hv="hv"
																:rowclicked="(item) => rowClicked(item)"
																:i="generateSecureId(item.id + item.date + item.fullName + item.departmentName)"
																:useComponent="true"
																:groupByFilter="groupActionCellTimesheets"
																:deleteAction="(item, idTable) => deleteAction(item, idTable)"
																:hideModal="hideModal"
																@add:Submitted="handleRefreshData"
															/>
														</div>
													</div>
												</div>
											</b-card-text>
										</b-tab>
									</b-tabs>
								</b-card>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import { store } from '@/store';
import PlanningViewTables from '@/components/EntryForCrew/Timesheets/TimesheetsEfcTables/PlanningViewTables.vue';
import Loading from 'vue-loading-overlay';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import TimeCardForCrewModal from '../../timesheet/TimeCardForCrewModal.vue';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import moment from 'moment';
import HeaderTimesheets from '@/components/EntryForCrew/Timesheets/HeaderTimesheets/HeaderTimesheets';
import TimesheetsEfcTables from '@/components/EntryForCrew/Timesheets/TimesheetsEfcTables/TimesheetsEfcTables';
import { AlertTriangle } from 'lucide-vue';
import TimesheetsPerDateView from './TimesheetsPerDateView.vue';
// import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import {
	getProjectUsersTsDaysPlanning,
	validateTsDays,
	getProjectOnlyUserTsDay,
	getProjectOnlyUserTimesheets,
	deleteTimeCardsTsDay
} from '@/cruds/timesheets-efc.crud';
import { FILTER_STRING_TIME_SHEET, NAME_CARDS_TIMESHEET_EFC, ACTION_EFC_TIMESHEET } from '@/utils/utils';
import CustomLoadingPlanning from '@/components/Plannings/Filter/CustomLoadingPlanning';
import MiniHeaderTimesheet from './MinitHeaderTimesheet.vue';
import { mapActions } from 'vuex';
import { rendCurrency } from '~helpers';
import { GetEncodingValidation } from '@/cruds/users.crud';
import { getDepartments } from '@/cruds/department.crud';
import { submitSelectedTimesheet } from '@/cruds/timesheet.crud';
import { generateSecureId } from '@/shared/utils';
export default {
	name: 'TimesheetsEntryCrew',
	components: {
		TimesheetsPerDateView,
		Loading,
		AlertTriangle,
		HeaderTimesheets,
		TimesheetsEfcTables,
		CustomLoadingPlanning,
		TimeCardForCrewModal,
		MiniHeaderTimesheet,
		PlanningViewTables
	},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		caption1: {
			type: String,
			default: 'My Timesheets'
		},
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data: () => {
		const { groupByDate, groupByDateOnly, groupByPerson, groupByStatus, planningView } = FILTER_STRING_TIME_SHEET;
		const { timecards_One, timesheets_Two } = NAME_CARDS_TIMESHEET_EFC;
		const { addData, updateData, deleteData } = ACTION_EFC_TIMESHEET;
		return {
			hv: 'dark',
			Validated: 0,
			error: {},
			TimeCards: [],
			dataUserPlanning: [],
			DateTimeCards: [],
			Timesheets: [],
			currentPage: 1,
			perPage: 0,
			warning: '',
			successModal: false,
			isLoading: false,
			filter: '',
			timecards_One,
			timesheets_Two,
			configAccordion: {
				show: {
					isVisible: false
				}
			},
			Open: false,
			curTs: {
				id: 0,
				strDate: '',
				endDate: '',
				hours: '',
				minutes: '',
				hoursOvertime: '',
				minutesOvertime: '',
				hoursOvertime1: '',
				minutesOvertime1: '',
				hoursOvertime2: '',
				minutesOvertime2: '',
				hoursTransportTimePaid: '',
				minutesTransportTimePaid: '',
				hoursNight: '',
				minutesNight: '',
				hoursTooEarly: '',
				minutesTooEarly: '',
				salary: 0,
				kgCoTwo: 0,
				validated: 0,
				validatedStatus: 'Not Submited'
			},
			TabAction: timecards_One,
			groupActionCellTimeCards: '',
			groupActionCellTimesheets: '',
			groupByActionValue: null,
			groupByPerson,
			groupByDate,
			groupByDateOnly,
			groupByStatus,
			planningView,
			filterByPerson: false,
			filterByStatus: false,
			filterByDate: false,
			filterPlanning: true,
			allDataTimeCards: [],
			allDataTimesheets: [],
			showGridDetails: false,
			editData: {},
			addData,
			updateData,
			deleteData,
			isActionCrud: '',
			timesheetActiveTab: 0,
			expenseStatusDefault: 0,
			miniSpinnerLoadingTimeCards: false,
			miniSpinnerLoadingTimeSheet: false,
			generateSecureId,
			DateDefaultSelect: {
				value: moment(Date.now()).format('MM/YYYY'),
				text: moment(Date.now()).format('MMMM YYYY')
			},
			isRefresh: false,
			headerTabPlanning: [],
			rendCurrency,
			dataPlanningSelect: [],
			isNavigateInitDate: false,
			planningToSend: [],
			recordOffSet: 0,
			recordLimit: 20,
			recordLimitPerDate: 10,
			recordOffSetPerDate: 0,
			allDepartment: [],
			departmentAutorization: [],
			totalAllPageRecords: 0,
			isPersistDataPlanning: false,
			successModal: false,
			valueToSearch: null,
			departmentPersist: {
				id: null,
				value: null,
				message: null
			},
			useFormInModal: true,
			fromMount: true
		};
	},
	computed: {
		fieldsTimeCards() {
			return [
				{
					key: 'select',
					label: this.FormMSG(90, 'Select')
				},
				{
					key: 'status',
					label: this.FormMSG(43, 'Status'),
					sortable: true
				},
				{
					key: 'date',
					label: this.FormMSG(41, 'Date'),
					formatter: (value) => {
						return new moment(value).format('DD/MM/YYYY');
					},
					sortable: true
				},
				{
					key: 'crew_member',
					label: this.FormMSG(42, 'Crew member'),
					sortable: true
				},
				{
					key: 'encoder',
					label: this.FormMSG(92, 'Encoded By'),
					sortable: false
				},
				{
					key: 'tsDay.strTime',
					label: this.FormMSG(26, 'Start'),
					formatter: (value) => {
						return moment(value).format('HH:mm');
					},
					sortable: true
				},
				{
					key: 'tsDay.endTime',
					label: this.FormMSG(27, 'End'),
					formatter: (value) => {
						return moment(value).format('HH:mm');
					},
					sortable: false
				},
				{
					key: 'worked',
					label: this.FormMSG(44, 'Worked'),
					sortable: true
				},
				{
					key: 'dayType',
					label: this.FormMSG(45, 'Type'),
					sortable: true
				},
				{
					key: 'ovt',
					label: this.FormMSG(46, 'Ovt'),
					sortable: true
				},
				this.transport,
				this.nigth,
				this.anticipated,
				this.preCall,
				{
					key: 'lunch',
					label: this.FormMSG(51, 'Lunch'),
					sortable: true
				},
				this.totPerDiem,
				{
					key: 'allowances',
					label: this.FormMSG(53, 'Allowances'),
					sortable: true
				},
				{
					key: 'dayRate',
					label: this.FormMSG(54, 'Rate'),
					sortable: true
				},
				{
					key: 'co2',
					label: this.FormMSG(55, 'CO2'),
					sortable: true
				},
				{
					key: 'salary',
					label: this.FormMSG(56, 'Salary'),
					sortable: true
				},
				{
					key: 'remove',
					label: this.FormMSG(57, 'Remove'),
					sortable: false
				}
			];
		},
		fieldsTimeCardsPlanning() {
			return this.headerTabPlanning;
		},
		fieldsTimesheets() {
			return [
				{
					key: 'select',
					label: this.FormMSG(58, 'Select')
				},
				{
					key: 'status',
					label: this.FormMSG(60, 'Status'),
					sortable: true
				},
				{
					key: 'startDate',
					label: this.FormMSG(26, 'Start'),
					formatter: (value) => {
						return new moment(value).format('DD/MM/YYYY');
					},
					sortable: true
				},
				{
					key: 'endDate',
					label: this.FormMSG(27, 'End'),
					formatter: (value) => {
						return new moment(value).format('DD/MM/YYYY');
					},
					sortable: false
				},
				{
					key: 'crew_member',
					label: this.FormMSG(59, 'Crew member'),
					sortable: true
				},
				{
					key: 'encoder',
					label: this.FormMSG(92, 'Encoded By'),
					sortable: false
				},
				{
					key: 'worked',
					label: this.FormMSG(61, 'Worked'),
					sortable: true
				},
				this.weeklyOverTime,
				this.dailyOvertime,
				this.transport,
				this.nigth,
				this.anticipated,
				this.preCall,
				this.perDiem,
				{
					key: 'allowances',
					label: this.FormMSG(69, 'Allowances'),
					sortable: true
				},
				{
					key: 'co2',
					label: this.FormMSG(70, 'CO2'),
					sortable: true
				},
				{
					key: 'salary',
					label: this.FormMSG(71, 'Salary'),
					sortable: true
				},
				{
					key: 'remove',
					label: this.FormMSG(72, 'Remove'),
					sortable: false
				}
			];
		},
		currentExpenseTab: {
			get() {
				if (this.$route.query.activeSecondTab) {
					this.TabAction = this.timesheets_Two;
					return 1;
				}
				return this.timesheetActiveTab;
			},
			set(tab) {
				this.handleTabChanged(tab);
			}
		},
		totPerDiem() {
			if (this.showLunchPerDiem || this.showHotelPerDiem || this.showDinnerPerDiem || this.showAbroadPerDiem) {
				return {
					key: 'totPerDiem',
					label: this.FormMSG(52, 'Per Diem'),
					sortable: true
				};
			}
		},
		transport() {
			if (this.showTransport) {
				return {
					key: 'transport',
					label: this.FormMSG(47, 'Transport'),
					sortable: true
				};
			}
		},
		weeklyOverTime() {
			if (this.showWeeklyOvt || this.showWeeklyOvt1 || this.showWeeklyOvt2) {
				return {
					key: 'weeklyOvt',
					label: this.FormMSG(62, 'Weekly ovt'),
					sortable: true
				};
			}
		},
		dailyOvertime() {
			if (this.showOvt || this.showOvt1 || this.showOvt2) {
				return {
					key: 'dailyOvt',
					label: this.FormMSG(63, 'Daily Ovt'),
					sortable: true
				};
			}
		},
		anticipated() {
			if (this.showTooEarlyHours) {
				return {
					key: 'anticipated',
					label: this.FormMSG(49, 'Anticipated'),
					sortable: true
				};
			}
		},
		nigth() {
			if (this.showNightHours) {
				return {
					key: 'night',
					label: this.FormMSG(48, 'Night'),
					sortable: true
				};
			}
		},
		perDiem() {
			if (this.showLunchPerDiem || this.showHotelPerDiem || this.showDinnerPerDiem || this.showAbroadPerDiem) {
				return {
					key: 'perDiem',
					label: this.FormMSG(68, 'Per Diem'),
					sortable: true
				};
			}
		},
		preCall() {
			if (this.showPreCall) {
				return {
					key: 'precall',
					label: this.FormMSG(50, 'Precall'),
					sortable: true
				};
			}
		},
		showOvt() {
			return store.getCurrentProjectConfig().useOvertime;
		},
		showOvt1() {
			return store.getCurrentProjectConfig().useOvt1;
		},
		showOvt2() {
			return store.getCurrentProjectConfig().useOvt2;
		},
		showWeeklyOvt() {
			return store.getCurrentProjectConfig().useWeeklyOvt;
		},
		showWeeklyOvt1() {
			return store.getCurrentProjectConfig().useWeeklyOvt1;
		},
		showWeeklyOvt2() {
			return store.getCurrentProjectConfig().useWeeklyOvt2;
		},
		showTransport() {
			return store.getCurrentProjectConfig().useHomeToWorkTime;
		},
		showLunchPerDiem() {
			return store.getCurrentProjectConfig().useLunchPerDiem;
		},
		showHotelPerDiem() {
			return store.getCurrentProjectConfig().useHotelPerDiem;
		},
		showDinnerPerDiem() {
			return store.getCurrentProjectConfig().useDinnerPerDiem;
		},
		showAbroadPerDiem() {
			return store.getCurrentProjectConfig().useAbroadPerDiem;
		},
		showTooEarlyHours() {
			return store.getCurrentProjectConfig().useTooEarlyHours;
		},
		showNightHours() {
			return store.getCurrentProjectConfig().useNightHours;
		},
		showPreCall() {
			return store.getCurrentProjectConfig().useBeforeCallTime;
		},
		rendConfirmModalTxt() {
			const _t = this.FormMSG;
			return {
				title: _t(235, 'Please Confirm'),
				okTitle: _t(236, 'YES'),
				cancelTitle: _t(237, 'NO')
			};
		}
	},
	watch: {
		'$screen.width': {
			handler(val) {
				if (val <= 567) {
					this.showGridDetails = true;
				}
			},
			immediate: true
		},
		currentExpenseTab: {
			async handler(newVal) {
				if (newVal === 1) {
					if (this.$route.query.activeFilter == this.groupByDate || !this.$route.query.activeFilter) {
						this.miniSpinnerLoadingTimeSheet = true;
						this.groupActionCellTimesheets = this.groupByDate;
						this.groupActionCellTimesheets = this.groupByDate;
						this.groupByActionValue = true;
						this.TabAction = this.timesheets_Two;
						let resultTimesheets = await this.getProjectUserSalariesDataInitial();
						this.Timesheets = resultTimesheets;
						this.allDataTimesheets = resultTimesheets;
						this.formChanged(this.DateDefaultSelect, this.timesheets_Two);
						this.miniSpinnerLoadingTimeSheet = false;
						this.currentExpenseTab = 1;
					} else {
						setTimeout(async () => {
							this.miniSpinnerLoadingTimeSheet = true;
							await this.setFilter(this.$route.query.activeFilter);
							this.miniSpinnerLoadingTimeSheet = false;
						}, 250);
					}
				} else {
					this.currentExpenseTab = 0;
				}
			},
			deep: true,
			immediate: true
		},
		TimeCards: {
			handler(val) {
				return val;
			},
			immediate: true,
			deep: true
		},
		departmentPersist: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	async created() {
		if (this.$route.query.activeSecondTab) {
			this.setTabAction(this.timesheets_Two);
		} else {
			this.fromMount = false;
			this.reloadData();
		}
	},
	methods: {
		handleRefreshReject() {
			this.handleRefreshData();
		},
		async handleRefreshData() {
			if (this.groupActionCellTimeCards !== this.planningView) {
				try {
					this.$nextTick(() => {
						this.miniSpinnerLoadingTimeCards = true;
						if (this.TabAction === this.timecards_One) {
							this.setFilter(this.groupActionCellTimeCards);
						}
						if (this.TabAction === this.timesheets_Two) {
							this.setFilter(this.groupActionCellTimesheets);
						}
						this.miniSpinnerLoadingTimeCards = false;
						this.hideModal();
					});
				} catch (error) {
					console.error(error);
				}
			} else {
				let arrays = [];
				for (let i = 0; i <= this.recordOffSet; i++) {
					let Filter = {
						...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`, 'DD/MM/YYYY', true).format()),
						recordLimit: this.recordLimit,
						recordOffSet: i,
						department: this.departmentPersist.value,
						keyWord: this.valueToSearch
					};
					let result = [...(await getProjectUsersTsDaysPlanning(Filter))].map((item) => ({ ...item, isSelected: false }));
					arrays.push(result);
				}
				let data = arrays.flat(Infinity);
				this.TimeCards = data;
				this.hideModal();
			}
		},
		async handleSelectedDay(data) {
			let result = [];
			if (data == -1) {
				result = [
					...(await getProjectOnlyUserTsDay({
						startDate: moment(this.DateDefaultSelect.value, 'MM/YYYY').startOf('month').format('YYYY-MM-DD') + 'T00:00:00Z',
						endDate: moment(this.DateDefaultSelect.value, 'MM/YYYY').endOf('month').format('YYYY-MM-DD') + 'T00:00:00Z'
					}))
				].map((item) => ({
					...item,
					crew_member: item.fullName,
					validatedText: this.GetTextFromMenuNumberAndMenuValue(1008, item.status),
					data: [{ ...item, crew_member: item.fullName }]
				}));
				this.TimeCards = result;
			} else {
				result = [
					...(await getProjectOnlyUserTsDay({
						startDate: moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T00:00:00Z',
						endDate: moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T00:00:00Z'
					}))
				].map((item) => ({
					...item,
					crew_member: item.fullName,
					validatedText: this.GetTextFromMenuNumberAndMenuValue(1008, item.status),
					data: [{ ...item, crew_member: item.fullName }]
				}));
				this.TimeCards = result;
			}
		},
		handleSelectedFormFilter(data) {
			this.recordLimitPerDate = 10;
			this.recordOffSetPerDate = 0;
			this.DateDefaultSelect = data.e;
			this.formChanged(data.e, data.id);
		},
		...mapActions({
			handleTabChanged: 'expenses/setActiveTab'
		}),
		async setFilter(item) {
			this.filter = '';
			this.groupActionCellTimeCards = item;
			this.groupActionCellTimesheets = item;
			this.TimeCards = [];
			this.Timesheets = [];
			this.allDataTimeCards = [];
			this.allDataTimesheets = [];
			this.planningToSend = [];
			this.dataPlanningSelect = [];
			this.isLoading = this.miniSpinnerLoadingTimeCards || this.miniSpinnerLoadingTimeSheet ? false : true;
			if (this.TabAction === this.timecards_One && this.groupActionCellTimeCards === this.planningView) {
				this.isLoading = true;
				let arrays = [];
				for (let i = 0; i <= this.recordOffSet; i++) {
					let Filter = {
						...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`, 'DD/MM/YYYY', true).format()),
						recordLimit: this.recordLimit,
						recordOffSet: i,
						department: this.departmentPersist.value,
						keyWord: this.valueToSearch
					};
					delete Filter.activateCrewAccessRestriction;
					let result = [...(await getProjectUsersTsDaysPlanning(Filter))].map((item) => ({ ...item, isSelected: false }));
					arrays.push(result);
				}
				let data = arrays.flat(Infinity);
				let _dateSplit = this.DateDefaultSelect.value.split`/`;
				let month = +_dateSplit[0];
				let year = +_dateSplit[1];
				const nbDay = new Date(year, month, 0).getDate();
				let dateHeader = [];
				const langCurrent = this.currentLang;
				for (let n = 1; n <= nbDay; n++) {
					let formattedDate = moment(n.toString().padStart('2', '0') + '/' + this.DateDefaultSelect.value, 'DD/MM/YYYY')
						.locale(`${langCurrent}`)
						.format('DD ddd');
					dateHeader.push({
						key: `${n.toString().padStart('2', '0') + '/' + this.DateDefaultSelect.value}`,
						label: formattedDate,
						sortable: true
					});
				}
				this.headerTabPlanning = [
					{
						key: 'crew_member',
						label: this.FormMSG(42, 'Crew member'),
						sortable: true
					},
					...dateHeader
				];
				this.TimeCards = data;
				this.allDataTimeCards = data;
				this.isLoading = false;
				return;
			}
			if (this.TabAction === this.timecards_One && this.groupActionCellTimeCards === this.groupByDate) {
				this.TimeCards = await this.getProjectUserTsDaysDataInitial();
				this.allDataTimeCards = this.TimeCards;
				this.formChanged(this.DateDefaultSelect, this.timecards_One);
				this.isLoading = false;
				return;
			}
			if (
				this.TabAction === this.timecards_One &&
				(this.groupActionCellTimeCards === this.groupByPerson || this.groupActionCellTimeCards === this.groupByStatus)
			) {
				this.TimeCards = await this.getProjectUserTsDaysDataInitial();
				this.allDataTimeCards = this.TimeCards;
				this.isLoading = false;
				return;
			}
			if (this.TabAction === this.timesheets_Two && this.groupActionCellTimesheets === this.groupByDate) {
				this.Timesheets = await this.getProjectUserSalariesDataInitial();
				this.allDataTimesheets = this.Timesheets;
				this.formChanged(this.DateDefaultSelect, this.timesheets_Two);
				this.isLoading = false;
				return;
			}
			if (this.TabAction === this.timesheets_Two && this.groupActionCellTimesheets === this.groupByPerson) {
				this.Timesheets = await this.getProjectUserSalariesDataInitial();
				this.allDataTimesheets = this.Timesheets;
				this.isLoading = false;
				return;
			}
			if (this.TabAction === this.timesheets_Two && this.groupActionCellTimesheets === this.groupByStatus) {
				this.Timesheets = [...new Set([...(await this.getProjectUserSalariesDataInitial())].map((item) => item.status))].map((_status) => {
					return {
						id: _status,
						date: 'date-01-04-' + Math.floor(Math.random() * 1000000),
						status: _status,
						data: [
							{
								id: _status,
								date: 'date-01-04-' + Math.floor(Math.random() * 1000000),
								status: _status,
								timesheet: {
									id: _status
								}
							}
						]
					};
				});
				this.allDataTimesheets = this.Timesheets;
				this.isLoading = false;
				return;
			}
		},
		showModal() {
			this.isActionCrud = this.addData;
			this.Open = true;
		},
		hideModal() {
			this.Open = false;
			//if (this.dataPlanningSelect.length !== 0) {
			// for (let planning of this.dataPlanningSelect) {
			// 	let isSecuredId = document.getElementById(planning.securedId);
			// 	if (planning.data !== undefined) {
			// 		if (isSecuredId !== null) {
			// 			isSecuredId.style.backgroundColor = '#ffff';
			// 		}
			// 	} else {
			// 		if (isSecuredId !== null) {
			// 			isSecuredId.style.backgroundColor = '#f3f4f5';
			// 		}
			// 	}
			// }
			//}
			this.dataPlanningSelect = [];
			this.planningToSend = [];
			this.editData = {};
			this.isActionCrud = '';
			this.isPersistDataPlanning = false;
		},
		closeModal() {
			this.Open = false;
			this.isPersistDataPlanning = true;
			this.editData = {};
		},
		async addDataFunc() {
			this.isRefresh = true;
			this.setTabAction(this.timecards_One);
		},
		async reloadData() {
			this.departmentAutorization = (await GetEncodingValidation(store.getCurrentProjectConfig().id, store.userID())).department;
			this.allDepartment = await getDepartments(false, false);
			await this.getDataInit();
		},
		setFilterName(name, value) {
			let obj = {};
			obj[name] = value;
			return { ...obj, projectId: +store.projectID(), activateCrewAccessRestriction: true };
		},
		async getDataInit() {
			this.groupActionCellTimeCards = this.planningView;
			this.groupActionCellTimesheets = this.planningView;
			this.groupByActionValue = this.filterPlanning;
			this.isLoading = this.miniSpinnerLoadingTimeCards || this.miniSpinnerLoadingTimeSheet ? false : true;
			let data = [...(await this.getProjectUsersTsDaysPlanningDataInitial())];
			let _dateSplit = this.DateDefaultSelect.value.split`/`;
			let month = +_dateSplit[0];
			let year = +_dateSplit[1];
			const nbDay = new Date(year, month, 0).getDate();
			let dateHeader = [];
			const langCurrent = this.currentLang;
			for (let n = 1; n <= nbDay; n++) {
				let formattedDate = moment(n.toString().padStart('2', '0') + '/' + this.DateDefaultSelect.value, 'DD/MM/YYYY')
					.locale(`${langCurrent}`)
					.format('DD ddd');
				dateHeader.push({
					key: `${n.toString().padStart('2', '0') + '/' + this.DateDefaultSelect.value}`,
					label: formattedDate,
					sortable: true
				});
			}
			this.headerTabPlanning = [
				{
					key: 'crew_member',
					label: this.FormMSG(42, 'Crew member'),
					sortable: true
				},
				...dateHeader
			];
			this.TimeCards = data;
			this.allDataTimeCards = data;
			this.isLoading = false;
		},
		async getProjectUsersTsDaysPlanningDate() {
			let result = [...(await getProjectUsersTsDaysPlanning())];
			return result;
		},
		async getProjectUsersTsDaysPlanningDataInitial() {
			let Filter = {
				...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`, 'DD/MM/YYYY', true).format()),
				recordLimit: this.recordLimit,
				recordOffSet: this.recordOffSet,
				department: this.departmentPersist.value,
				keyWord: this.valueToSearch
			};
			delete Filter.activateCrewAccessRestriction;
			let result = [...(await getProjectUsersTsDaysPlanning(Filter))].map((item) => ({ ...item, isSelected: false }));
			return result;
		},
		async getProjectUserTsDaysDataInitial() {
			let result = [];
			let filter = {};
			if (this.TabAction === this.timecards_One && this.groupActionCellTimeCards === this.groupByPerson) {
				filter = {
					...this.setFilterName(this.groupByPerson, this.groupByActionValue),
					activateCrewAccessRestriction: true
				};
			}
			if (this.TabAction === this.timecards_One && this.groupActionCellTimeCards === this.groupByStatus) {
				filter = {
					...this.setFilterName(this.groupByStatus, this.groupByActionValue),
					activateCrewAccessRestriction: true
				};
			}
			if (this.TabAction === this.timecards_One && this.groupActionCellTimeCards === this.groupByDate) {
				filter = {
					startDate: moment(this.DateDefaultSelect.value, 'MM/YYYY').startOf('month').format('YYYY-MM-DD') + 'T00:00:00Z',
					endDate: moment(this.DateDefaultSelect.value, 'MM/YYYY').endOf('month').format('YYYY-MM-DD') + 'T00:00:00Z',
					recordLimit: this.recordLimitPerDate,
					recordOffSet: this.recordOffSetPerDate,
					activateCrewAccessRestriction: true
				};
			}
			result = [...(await getProjectOnlyUserTsDay(filter))].map((item) => ({
				...item,
				crew_member: item.fullName,
				validatedText: this.GetTextFromMenuNumberAndMenuValue(1008, item.status),
				data: [{ ...item, crew_member: item.fullName }]
			}));
			delete filter.recordLimit;
			delete filter.recordOffSet;
			let setDataFilterAllRecords = {
				...filter
			};
			this.totalAllPageRecords = [...(await getProjectOnlyUserTsDay(setDataFilterAllRecords))].length;
			return result;
		},
		async getProjectUserSalariesDataInitial() {
			let Filter = {};
			if (this.TabAction === this.timesheets_Two && this.groupActionCellTimesheets === this.groupByDate) {
				Filter = {
					...this.setFilterName(this.groupByDateOnly, this.groupByActionValue),
					activateCrewAccessRestriction: true
				};
			} else {
				Filter = {
					...this.setFilterName(this.groupActionCellTimesheets, this.groupByActionValue),
					activateCrewAccessRestriction: true
				};
			}
			let result = [...(await getProjectOnlyUserTimesheets(Filter))].map((item) => ({
				...item,
				isSelected: false,
				crew_member: item.fullName,
				data: [{ ...item, crew_member: item.fullName }]
			}));
			return result;
		},
		async initDataPlanningView() {
			let arrays = [];
			for (let i = 0; i <= this.recordOffSet; i++) {
				let Filter = {
					...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`, 'DD/MM/YYYY', true).format()),
					recordLimit: this.recordLimit,
					recordOffSet: i,
					department: this.departmentPersist.value,
					keyWord: this.valueToSearch
				};
				let result = [...(await getProjectUsersTsDaysPlanning(Filter))].map((item) => ({ ...item, isSelected: false }));
				arrays.push(result);
			}
			let data = arrays.flat(Infinity);
			this.TimeCards = data;
			this.allDataTimeCards = data;
		},
		async onSearch(searchValue, action, idTable) {
			this.filter = searchValue;
			this.valueToSearch = searchValue.replace(/[\s]+/gi, '%');
			if (idTable === this.timecards_One) {
				let DATA = this.allDataTimeCards;
				if (this.filterPlanning === true && this.groupActionCellTimeCards === this.planningView) {
					if (searchValue.length === 0) {
						this.TimeCards = DATA;
					} else {
						if (action) {
							this.TimeCards = DATA;
						} else {
							let arrays = [];
							for (let i = 0; i <= this.recordOffSet; i++) {
								let Filter = {
									...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`, 'DD/MM/YYYY', true).format()),
									recordLimit: this.recordLimit,
									recordOffSet: i,
									department: this.departmentPersist.value,
									keyWord: this.valueToSearch
								};
								let result = [...(await getProjectUsersTsDaysPlanning(Filter))].map((item) => ({ ...item, isSelected: false }));
								arrays.push(result);
							}
							let data = arrays.flat(Infinity);
							this.TimeCards = data;
						}
					}
				} else {
					if (searchValue.length === 0) {
						this.TimeCards = DATA;
					} else {
						if (action) {
							this.TimeCards = DATA;
						} else {
							this.TimeCards = DATA.filter((item) => {
								if (
									item.fullName.toUpperCase().includes(searchValue.toUpperCase()) ||
									item.functionName.toUpperCase().includes(searchValue.toUpperCase())
								) {
									return true;
								}
								return false;
							});
						}
					}
				}
			}
			if (idTable === this.timesheets_Two) {
				let DATA = this.allDataTimesheets;
				if (searchValue.length === 0) {
					this.Timesheets = DATA;
				} else {
					if (action) {
						this.Timesheets = DATA;
					} else {
						if (searchValue.length <= 1) {
							this.Timesheets = DATA;
						} else {
							this.Timesheets = DATA.filter((item) => {
								if (
									item.fullName.toUpperCase().includes(searchValue.toUpperCase()) ||
									item.functionName.toUpperCase().includes(searchValue.toUpperCase())
								) {
									return true;
								}
								return false;
							});
						}
					}
				}
			}
		},
		async formChanged(_date, idTable) {
			// on change date to filter by date
			if (this.isRefresh === false) {
				if (idTable === this.timecards_One) {
					let DATA = this.allDataTimeCards;
					if (_date.value !== 0) {
						this.miniSpinnerLoadingTimeCards = true;
						this.TimeCards = await this.getProjectUserTsDaysDataInitial();
						this.miniSpinnerLoadingTimeCards = false;
					}
				}
				if (idTable === this.timesheets_Two) {
					let DATA = this.allDataTimesheets;
					if (_date.value !== 0) {
						this.isLoading = true;
						this.Timesheets = DATA.filter((item) => {
							if (moment(item.startDate).format('MM/YYYY').includes(_date.value)) {
								return true;
							}
							return false;
						});
						this.isLoading = false;
					}
				}
			} else {
				let resultTimeCardsDATA = await this.getProjectUserTsDaysDataInitial();
				this.allDataTimeCards = resultTimeCardsDATA;
				let DATA = this.allDataTimeCards;
				this.TimeCards = DATA.filter((item) => {
					if (moment(item.date).format('MM/YYYY').includes(_date.value)) {
						return true;
					}
					return false;
				});
				this.isRefresh = false;
			}
		},
		async formChangedPlanningView(_date, idTable) {
			this.isLoading = true;
			let _dateSplit = _date.value.split`/`;
			let month = +_dateSplit[0] + 1;
			let year = +_dateSplit[1];
			const nbDay = new Date(year, month, 0).getDate();
			let dateHeader = [];
			const langCurrent = this.currentLang;

			for (let n = 1; n <= nbDay; n++) {
				let formattedDate = moment(n.toString().padStart('2', '0') + '/' + _date.value, 'DD/MM/YYYY')
					.locale(`${langCurrent}`)
					.format('DD ddd');
				dateHeader.push({
					key: `${n.toString().padStart('2', '0') + '/' + _date.value}`,
					label: formattedDate,
					sortable: true
				});
			}

			this.headerTabPlanning = [
				{
					key: 'crew_member',
					label: this.FormMSG(42, 'Crew member'),
					sortable: true
				},
				...dateHeader
			];

			this.DateDefaultSelect = _date;

			let arrays = [];
			for (let i = 0; i <= this.recordOffSet; i++) {
				let Filter = {
					...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`, 'DD/MM/YYYY', true).format()),
					recordLimit: this.recordLimit,
					recordOffSet: i,
					department: this.departmentPersist.value,
					keyWord: this.valueToSearch
				};
				let result = [...(await getProjectUsersTsDaysPlanning(Filter))].map((item) => ({ ...item, isSelected: false }));
				arrays.push(result);
			}
			let data = arrays.flat(Infinity);
			this.allDataTimeCards = data;
			this.TimeCards = data;
			this.isLoading = false;
		},
		async deleteAction(item, idTable) {
			if (idTable === this.timecards_One) {
				let txt = this.FormMSG(74, 'Please confirm that you want delete this timesheet');

				let result = this.$bvModal
					.msgBoxConfirm(txt, {
						title: txt,
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'danger',
						okTitle: 'ok',
						cancelTitle: 'Cancel',
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then(async (v) => {
						if (!v) return;
						else {
							let result = await deleteTimeCardsTsDay(item.tsDay.id);
							result &&
								this.createToastForMobile(
									this.FormMSG(9, 'Success'),
									this.FormMSG(75, 'Timesheets item delete successfully'),
									'',
									'success',
									true
								);
							return result && true;
						}
					})
					.catch(() => {
						return false;
					});
				return result;
			}
			if (idTable === this.timesheets_Two) {
				let txt = this.FormMSG(74, 'Please confirm that you want delete this timesheet');

				let result = this.$bvModal
					.msgBoxConfirm(txt, {
						title: txt,
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'danger',
						okTitle: 'ok',
						cancelTitle: 'Cancel',
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then(async (v) => {
						if (!v) return;
						else {
							let result = await deleteTimeCardsTsDay(item.timesheet.id);
							result &&
								this.createToastForMobile(
									this.FormMSG(9, 'Success'),
									this.FormMSG(75, 'Timesheets item delete successfully'),
									'',
									'success',
									true
								);
							return result && true;
						}
					})
					.catch(() => {
						return false;
					});
				return result;
			}
		},
		async setTabAction(name) {
			const query = {
				tab: undefined,
				activeFilter: undefined
			};
			this.DateDefaultSelect = {
				value: moment(Date.now()).format('MM/YYYY'),
				text: moment(Date.now()).format('MMMM YYYY')
			};
			this.TabAction = name;
			this.groupByFilter = this.planningView;
			if (this.TabAction === this.timecards_One) {
				this.miniSpinnerLoadingTimeCards = true;
				this.groupActionCellTimeCards = this.planningView;
				this.groupActionCellTimesheets = '';
				this.groupByActionValue = this.filterPlanning;
				this.TimeCards = [];
				this.allDataTimeCards = [];
				let arrays = [];
				for (let i = 0; i <= this.recordOffSet; i++) {
					let Filter = {
						...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`, 'DD/MM/YYYY', true).format()),
						recordLimit: this.recordLimit,
						recordOffSet: i,
						department: this.departmentPersist.value,
						keyWord: this.valueToSearch
					};
					let result = [...(await getProjectUsersTsDaysPlanning(Filter))].map((item) => ({ ...item, isSelected: false }));
					arrays.push(result);
				}
				let data = arrays.flat(Infinity);
				this.allDataTimeCards = data;
				let _dateSplit = this.DateDefaultSelect.value.split`/`;
				let month = +_dateSplit[0];
				let year = +_dateSplit[1];
				const nbDay = new Date(year, month, 0).getDate();
				let dateHeader = [];
				const langCurrent = this.currentLang;
				for (let n = 1; n <= nbDay; n++) {
					let formattedDate = moment(n.toString().padStart('2', '0') + '/' + this.DateDefaultSelect.value, 'DD/MM/YYYY')
						.locale(`${langCurrent}`)
						.format('DD ddd');
					dateHeader.push({
						key: `${n.toString().padStart('2', '0') + '/' + this.DateDefaultSelect.value}`,
						label: formattedDate,
						sortable: true
					});
				}
				this.headerTabPlanning = [
					{
						key: 'crew_member',
						label: this.FormMSG(42, 'Crew member'),
						sortable: true
					},
					...dateHeader
				];
				this.TimeCards = data;
				this.miniSpinnerLoadingTimeCards = false;
			}
			if (this.TabAction === this.timesheets_Two) {
				this.allDataTimesheets = [];
				this.Timesheets = [];
				this.miniSpinnerLoadingTimeSheet = true;
				this.groupActionCellTimeCards = '';
				this.groupActionCellTimesheets = this.groupByDate;
				this.groupByActionValue = true;
				let resultTimesheets = await this.getProjectUserSalariesDataInitial();
				this.Timesheets = resultTimesheets;
				this.allDataTimesheets = resultTimesheets;
				this.formChanged(this.DateDefaultSelect, this.timesheets_Two);
				this.miniSpinnerLoadingTimeSheet = false;
				query.tab = this.timesheets_Two;
				if (this.$route.query.activeFilter) query.activeFilter = this.$route.query.activeFilter;
			}

			this.goTo(query.tab, query.activeFilter);
		},
		setStatusExpenseDefault(value) {
			this.expenseStatusDefault = value;
		},
		dateToLiteral(timesheetDate) {
			const langCurrent = this.currentLang;
			const fullLiteralDate = moment(timesheetDate).locale(`${langCurrent}`).format('DD MMMM YYYY');

			return fullLiteralDate;
		},
		async handleSelectDepartementChange(department) {
			this.departmentPersist = department;
			if (this.departmentPersist.value !== null) {
				let arrays = [];
				for (let i = 0; i <= this.recordOffSet; i++) {
					let Filter = {
						...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`, 'DD/MM/YYYY', true).format()),
						recordLimit: this.recordLimit,
						recordOffSet: i,
						department: this.departmentPersist.value,
						keyWord: this.valueToSearch
					};
					let result = [...(await getProjectUsersTsDaysPlanning(Filter))].map((item) => ({ ...item, isSelected: false }));
					arrays.push(result);
				}
				let data = arrays.flat(Infinity);
				this.allDataTimeCards = data;
				this.TimeCards = data;
			} else {
				this.isLoading = true;
				let arrays = [];
				for (let i = 0; i <= this.recordOffSet; i++) {
					let Filter = {
						...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`, 'DD/MM/YYYY', true).format()),
						recordLimit: this.recordLimit,
						recordOffSet: i,
						department: this.departmentPersist.value,
						keyWord: this.valueToSearch
					};
					let result = [...(await getProjectUsersTsDaysPlanning(Filter))].map((item) => ({ ...item, isSelected: false }));
					arrays.push(result);
				}
				let data = arrays.flat(Infinity);
				this.allDataTimeCards = data;
				this.TimeCards = data;
				this.isLoading = false;
			}
		},
		setDataSizeModalPlanning(dataPlanning) {
			this.dataPlanningSelect = dataPlanning;
			this.planningToSend = dataPlanning;
			if (this.editData !== null) {
				this.isActionCrud = this.updateData;
			} else {
				this.isActionCrud = this.addData;
			}
		},
		sendDataModalPlanning(dataPlanning) {
			this.dataPlanningSelect = dataPlanning;
			this.planningToSend = dataPlanning;
			if (this.editData !== null) {
				this.isActionCrud = this.updateData;
			} else {
				this.isActionCrud = this.addData;
			}
		},
		showModalPlanningContextUpdate(dataPlanning) {
			if (dataPlanning.length !== 0) {
				this.isActionCrud = this.updateData;
				this.dataPlanningSelect = dataPlanning;
				this.planningToSend = dataPlanning;
				let dataToEdit = {
					...dataPlanning[0].data,
					tsDay: {
						...dataPlanning[0].data.tsDays[0],
						user: {
							id: dataPlanning[0].userId
						}
					}
				};
				delete dataToEdit.tsDays;
				this.editData = dataToEdit;
				this.Open = true;
			} else {
				this.dataPlanningSelect = dataPlanning;
				this.planningToSend = dataPlanning;
				this.editData = {};
				this.Open = false;
			}
		},
		async sendForValidationPlanning(item) {
			const confirmationMessage =
				item.length === 1
					? this.FormMSG(239, `Please confirm that you want to send the timesheet for validation`)
					: this.FormMSG(238, `Please confirm that you want to send all unsent timesheets to validation`);

			this.$bvModal
				.msgBoxConfirm(confirmationMessage, {
					title: this.rendConfirmModalTxt.title,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'primary',
					okTitle: this.rendConfirmModalTxt.okTitle,
					cancelTitle: this.rendConfirmModalTxt.cancelTitle,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (value) => {
					if (value) {
						let arrays = [];
						this.isLoading = true;
						for (let i = 0; i < item.length; i++) {
							let id = +item[i].id;
							let result = await submitSelectedTimesheet(id);
							arrays.push(result);
						}
						if (arrays.every((item) => item === true)) {
							let successMessage =
								item.length === 1
									? this.FormMSG(13, 'The timesheet has been submitted for validation')
									: this.FormMSG(12, 'All timesheets have been submitted for validation');
							let strTitle = this.FormMSG(9, 'Success');
							this.createToastForMobile(strTitle, successMessage, '', 'success', true);
							this.handleRefreshData();
						} else {
							let strTitle = this.FormMSG(81, 'Warning');
							let warningMessage =
								item.length === 1
									? this.FormMSG(83, 'Check the timesheet because it has not been sent for validation')
									: this.FormMSG(82, 'Check the timesheet(s) because there is a timesheet that has not been sent for validation');

							this.createToastForMobile(strTitle, warningMessage, '', 'warning', true);
							this.handleRefreshData();
						}
						this.isLoading = false;
					}
				})
				.catch((err) => console.log(err));
		},
		async validateTsDays(item) {
			let id = +item[0].id;
			let result = await validateTsDays(id);
			if (result !== null) {
				this.successModal = true;
				this.handleRefreshData();
			}
		},
		changeDateDefaultSelect(item) {
			if (this.departmentPersist.value === null) {
				this.DateDefaultSelect = item;
				this.formChangedPlanningView(this.DateDefaultSelect, this.timecards_One);
			} else {
				this.DateDefaultSelect = item;
				this.formChangedPlanningView(this.DateDefaultSelect, this.timecards_One);
				setTimeout(() => {
					this.handleSelectDepartementChange(this.departmentPersist);
				}, 1000);
			}
		},
		setIsNavigateInitDate(boolean) {
			this.isNavigateInitDate = boolean;
		},
		async getDataFromPagePlanning(recordOffSet) {
			this.recordOffSet += recordOffSet;
			let data = [...(await this.getProjectUsersTsDaysPlanningDataInitial())];
			let nextDataTimeCards = [...this.TimeCards, ...data];
			let nextDataAllTimeCards = [...this.allDataTimeCards, ...data];
			this.TimeCards = nextDataTimeCards;
			this.allDataTimeCards = nextDataAllTimeCards;
		},
		resetDataAfterAddUpdate() {
			this.dataPlanningSelect = [];
			this.planningToSend = [];
		},
		deleteItemPlanning(item) {
			let _id = +item[0].tsDays[0].id;
			let txt = this.FormMSG(74, 'Please confirm that you want delete this timesheet');
			let result = this.$bvModal
				.msgBoxConfirm(txt, {
					title: txt,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: 'ok',
					cancelTitle: 'Cancel',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;
					else {
						let result = await deleteTimeCardsTsDay(_id);
						result &&
							this.createToastForMobile(this.FormMSG(9, 'Success'), this.FormMSG(75, 'Timesheets item delete successfully'), '', 'success', true);
						return (
							result &&
							(this.TimeCards = this.TimeCards.map((data) => {
								let res = {
									...data,
									tsDays: data.tsDays.filter((item) => +item.id !== _id)
								};
								return res;
							}))
						);
					}
				})
				.catch(() => {
					return false;
				});
			return result;
		},
		async refreshDataItemPerDate() {
			this.recordLimitPerDate = this.recordLimitPerDate;
			this.recordOffSetPerDate = this.recordOffSetPerDate;
			let result = await this.getProjectUserTsDaysDataInitial();
			this.TimeCards = result;
		},
		async changePageSelectLimit(page) {
			this.recordLimitPerDate = page;
			this.recordOffSetPerDate = 0;
			let result = await this.getProjectUserTsDaysDataInitial();
			this.TimeCards = result;
		},
		async handleNextPerDate(recordOffSetPerDate) {
			this.recordOffSetPerDate = recordOffSetPerDate;
			let result = await this.getProjectUserTsDaysDataInitial();
			this.TimeCards = result;
		},
		async handlePreviousPerDate(recordOffSetPerDate) {
			this.recordOffSetPerDate = recordOffSetPerDate;
			let result = await this.getProjectUserTsDaysDataInitial();
			this.TimeCards = result;
		},
		goTo(tab, filter) {
			if (!this.fromMount) {
				const query = {};
				if (tab == this.timesheets_Two) query['activeSecondTab'] = 1;
				if (filter) query['activeFilter'] = filter;
				this.$router.push({ query });
			} else this.fromMount = false;
		}
	}
};
</script>
