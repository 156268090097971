<template>
	<div style="background-color: #f5f7f9; padding: 16px; border-radius: 8px" class="d-flex flex-row justify-content-center align-items-start">
		<b-col class="p-0">
			<b-row class="d-flex align-items-center">
				<b-col cols="10">
					<h1 class="h-text-color">{{ convertTextDate(showDetailData.date) }}</h1>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="6" class="d-flex justify-content-start align-items-center">
					<h6 v-if="!isFilmSingle" class="h-text-color p-1">
						<span>
							{{ FormMSG(257, 'Encoded by:') }}
							<b>{{ encoder(showDetailData) }}</b>
						</span>
					</h6>
				</b-col>
				<b-col cols="6" class="d-flex justify-content-end align-items-center">
					<h6 v-if="!isFilmSingle" class="p-1">
						<div class="name-title">
							{{ showDetailData.user.name }} {{ showDetailData.user.firstName }}
							<span class="divider-dot" v-if="showDetailData.user.functionName.length > 0"></span>
							{{ showDetailData.user.functionName }}
						</div>
					</h6>
				</b-col>
			</b-row>
			<b-row style="margin-top: 20px">
				<b-col>
					<div class="details-timesheet">
						<ul>
							<li>
								<div class="column-1">{{ FormMSG(183, 'Status') }}</div>
								<div class="column-2">
									<div class="wrap-status d-flex justify-content-end align-items-end">
										<div :class="`status ${classStatus(showDetailData.validated)}`" style="font-size: 0.675rem">
											{{ getLabelStatus(showDetailData.validated) }}
										</div>
									</div>
								</div>
							</li>
							<li
								class="timesheet-comment border-0 rounded-0"
								v-if="
									(showDetailData.validated === 4 || showDetailData.validated === 16) &&
									showDetailData.comment &&
									showDetailData.comment.length > 0
								"
							>
								<p>{{ showDetailData.comment }}</p>
							</li>
							<li>
								<div class="column-1">{{ FormMSG(16, 'Worked') }}</div>
								<div class="column-2">{{ setWorked(showDetailData.hours, showDetailData.minutes) }}</div>
							</li>
							<li v-if="showWeeklyOvt() || showWeeklyOvt1() || showWeeklyOvt2()">
								<div class="column-1">{{ FormMSG(175, 'Overtime') }}</div>
								<div class="column-2 d-flex flex-row justify-content-end align-items-center">
									<button
										v-if="
											setTotalOvt(
												[showDetailData.hoursOvertime, showDetailData.hoursOvertime1, showDetailData.hoursOvertime2],
												[showDetailData.minutesOvertime, showDetailData.minutesOvertime1, showDetailData.minutesOvertime2]
											) !== '00:00'
										"
										class="btn-transparent text-color-rhapsody-in-blue"
										v-b-tooltip.hover.left.html="tooltipContentOvertime(showDetailData)"
									>
										<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" />
									</button>
									<div>
										{{
											setTotalOvt(
												[showDetailData.hoursOvertime, showDetailData.hoursOvertime1, showDetailData.hoursOvertime2],
												[showDetailData.minutesOvertime, showDetailData.minutesOvertime1, showDetailData.minutesOvertime2]
											)
										}}
									</div>
								</div>
							</li>
							<li>
								<div class="column-1">{{ FormMSG(180, 'Start') }}</div>
								<div class="column-2">{{ formatTime(showDetailData.strTime) }}</div>
							</li>
							<li>
								<div class="column-1">{{ FormMSG(181, 'End') }}</div>
								<div class="column-2">{{ formatTime(showDetailData.endTime) }}</div>
							</li>
							<li v-if="showTransport() || showNightHours() || showTooEarlyHours()">
								<div class="column-1">{{ FormMSG(20, 'Transport') }}</div>
								<div class="column-2">{{ setHours(showDetailData.hoursTransportTimePaid) }}:{{ showDetailData.minutesTransportTimePaid }}</div>
							</li>
							<li>
								<div class="column-1">{{ FormMSG(21, 'Night') }}</div>
								<div class="column-2">{{ setHours(showDetailData.hoursNight) }}:{{ showDetailData.minutesNight }}</div>
							</li>
							<li>
								<div class="column-1">{{ FormMSG(182, 'Allowances') }}</div>
								<div class="column-2 d-flex flex-row justify-content-end align-items-end" style="gap: 4px">
									<button
										v-if="showDetailData.totAllowances > 0"
										class="btn-transparent text-color-rhapsody-in-blue"
										v-b-tooltip.hover.left.html="tooltipContentAllowance(showDetailData)"
									>
										<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" />
									</button>
									<div>{{ formatCurrency(showDetailData.totAllowances) }}</div>
								</div>
							</li>
							<li>
								<div class="column-1">{{ FormMSG(22, 'Anticipated') }}</div>
								<div class="column-2">{{ setHours(showDetailData.hoursTooEarly) }}:{{ showDetailData.minutesTooEarly }}</div>
							</li>
							<li v-if="showPreCall()">
								<div class="column-1">{{ FormMSG(222, 'Precall') }}</div>
								<div class="column-2">{{ showDetailData.beforeCallTime }}</div>
							</li>
							<li v-if="showLunchPerDiem() || showHotelPerDiem() || showDinnerPerDiem() || showAbroadPerDiem()">
								<div class="column-1">{{ FormMSG(220, 'Per Diem') }}</div>
								<div class="column-2 d-flex flex-row justify-content-end align-items-center" style="gap: 4px">
									<button
										v-if="showDetailData.totPerDiem > 0"
										class="btn-transparent text-color-rhapsody-in-blue"
										v-b-tooltip.hover.left.html="tooltipContentPerIdem(showDetailData)"
									>
										<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" />
									</button>
									<div>{{ formatCurrency(showDetailData.totPerDiem) }}</div>
								</div>
							</li>
							<li v-if="showDetailData.dailyTotAllowances > 0 || showDetailData.weeklyTotAllowances > 0">
								<div class="column-1">{{ FormMSG(221, 'Allowances') }}</div>
								<div class="column-2">
									<div class="d-flex justify-content-end align-items-center">
										<button
											class="btn-transparent text-color-rhapsody-in-blue"
											v-b-tooltip.hover.left.html="tooltipTsContentAllowance(showDetailData)"
											v-if="showDetailData.dailyTotAllowances > 0 || showDetailData.weeklyTotAllowances > 0"
										>
											<component :is="getLucideIcon('Info')" color="#225CBD" :size="16" />
										</button>
										<div style="margin-top: 4px">
											{{ getCurrency(showDetailData.dailyTotAllowances + showDetailData.weeklyTotAllowances) }}
										</div>
									</div>
								</div>
							</li>
							<li>
								<div class="column-1">{{ FormMSG(23, 'Salary') }}</div>
								<div class="column-2">
									<div class="d-flex flex-row align-items-center justify-content-end w-100">
										<salary-log :item="showDetailData" class="cursor-pointer" />
										<div>{{ getCurrency(showDetailData.salary) }}</div>
									</div>
								</div>
							</li>
							<li>
								<div class="column-1">{{ FormMSG(102, 'CO2') }}</div>
								<div class="column-2">{{ getCO2(showDetailData.kgCoTwo) }}</div>
							</li>
						</ul>
					</div>
				</b-col>
			</b-row>
		</b-col>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { rendCurrency, rendKgCo2 } from '~helpers';
import moment from 'moment';
import { classStatus } from '@/shared/global-status';
import { Clock10, Sprout, Info } from 'lucide-vue';
import { store } from '@/store';
import SalaryLog from '@/components/Timesheet/SalaryLog';

export default {
	name: 'ShowDetailPlanningCell',
	components: { Clock10, Sprout, Info, SalaryLog },
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		showDetailData: {
			type: Object,
			required: false
		}
	},
	data() {
		const setTotalOvt = (hoursOvts, minutesOvts) => {
			if (hoursOvts !== undefined && minutesOvts !== undefined) {
				let hours = hoursOvts.reduce((a, b) => +a + +b, 0);
				let minutes = minutesOvts.reduce((a, b) => +a + +b, 0);
				return `${hours.toString().padStart('2', '0')}` + ':' + `${minutes.toString().padStart('2', '0')}`;
			}
		};
		const setWorked = (hour, minute) => {
			if (hour !== undefined && minute !== undefined) {
				return `${hour.toString().padStart('2', '0')}` + ':' + `${minute.toString().padStart('2', '0')}`;
			}
		};
		const setHours = (hour) => {
			if (hour !== undefined) {
				return `${hour.toString().padStart('2', '0')}`;
			}
		};
		return {
			setTotalOvt,
			setWorked,
			setHours,
			classStatus
		};
	},
	computed: {},
	created() {},
	methods: {
		encoder(data) {
			if (data.encoder !== null) {
				let firstName = data.encoder !== undefined ? data.encoder.firstName : '';
				let name = data.encoder !== undefined ? data.encoder.name : '';
				return `${firstName} ${name}`;
			}
		},
		formatCurrency(value) {
			return rendCurrency(value);
		},
		convertDate(date) {
			return moment(date).format('DD/MM/YYYY');
		},
		convertTextDate(value) {
			const langCurrent = this.currentLang;
			let result = moment(value).locale(`${langCurrent}`).format('DD MMMM YYYY');
			return result;
		},
		setFullName(item) {
			let fullName = item.fullName;
			return fullName;
		},
		getCurrency(value) {
			return rendCurrency(value);
		},
		getCO2(value) {
			return rendKgCo2(value);
		},
		getHhMmString(value) {
			return value;
		},
		getLabelStatus(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, value);
		},
		showHotelPerDiem() {
			return store.getCurrentProjectConfig().useHotelPerDiem;
		},
		showLunchPerDiem() {
			return store.getCurrentProjectConfig().useLunchPerDiem;
		},
		showLunch() {
			return store.getCurrentProjectConfig().useLunchTime;
		},
		showTooEarlyHours() {
			return store.getCurrentProjectConfig().useTooEarlyHours;
		},
		showNightHours() {
			return store.getCurrentProjectConfig().useNightHours;
		},
		showTransport() {
			//console.log("in show transport:",store.getCurrentProjectConfig());
			return store.getCurrentProjectConfig().useHomeToWorkTime;
		},
		showOvt2() {
			return store.getCurrentProjectConfig().useOvt2;
		},
		showOvt1() {
			return store.getCurrentProjectConfig().useOvt1;
		},
		showOvt() {
			return store.getCurrentProjectConfig().useOvertime;
		},
		showWeeklyOvt() {
			return store.getCurrentProjectConfig().useWeeklyOvt;
		},
		showWeeklyOvt1() {
			return store.getCurrentProjectConfig().useWeeklyOvt1;
		},
		showWeeklyOvt2() {
			return store.getCurrentProjectConfig().useWeeklyOvt2;
		},
		showPreCall() {
			return store.getCurrentProjectConfig().useBeforeCallTime;
		},
		tooltipTsContentAllowance(item) {
			let content = [];

			if (item.weeklyCarAllowance > 0) {
				content.push(this.FormMSG(105, 'Car: ') + this.getCurrency(item.weeklyCarAllowance));
			}

			if (item.weeklyComputerAllowance > 0) {
				content.push(this.FormMSG(106, 'Computer: ') + this.getCurrency(item.weeklyComputerAllowance));
			}

			if (item.weeklyPhoneAllowance > 0) {
				content.push(this.FormMSG(107, 'Phone: ') + this.getCurrency(item.weeklyPhoneAllowance));
			}

			if (item.weeklyProductionFeeAllowance > 0) {
				content.push(this.FormMSG(108, 'Production fee: ') + this.getCurrency(item.weeklyProductionFeeAllowance));
			}

			if (item.weeklyBoxKitAllowance > 0) {
				content.push(this.FormMSG(109, 'Box kit: ') + this.getCurrency(item.weeklyBoxKitAllowance));
			}

			if (item.dailyTotAllowances > 0) {
				content.push(this.FormMSG(118, 'Total Daily: ') + this.getCurrency(item.dailyTotAllowances));
			}

			return content.join('<br />');
		},
		tooltipContentOvertime(item) {
			let content = [];
			if (item.salaryOvertime > 0) {
				content.push(
					this.FormMSG(360, 'Overtime') +
						': ' +
						this.setWorked(item.hoursOvertime, item.minutesOvertime) +
						' | ' +
						this.formatCurrency(item.salaryOvertime)
				);
			}

			if (item.salaryOvertime1 > 0) {
				content.push(
					this.FormMSG(361, 'Overtime 1') +
						': ' +
						this.setWorked(item.hoursOvertime1, item.minutesOvertime1) +
						' | ' +
						this.formatCurrency(item.salaryOvertime1)
				);
			}

			if (item.salaryOvertime2 > 0) {
				content.push(
					this.FormMSG(363, 'Overtime 2') +
						': ' +
						this.setWorked(item.hoursOvertime2, item.minutesOvertime2) +
						' | ' +
						this.formatCurrency(item.salaryOvertime2)
				);
			}

			return content.join('<br />');
		},
		tooltipContentPerIdem(item) {
			let content = [];
			if (item.hotel && item.hotelPerDiem > 0) {
				content.push(this.FormMSG(103, 'Hotel: ') + this.getCurrency(item.hotelPerDiem));
			}

			if (item.lunch && item.lunchPerDiem > 0) {
				content.push(this.FormMSG(104, 'Lunch: ') + this.getCurrency(item.lunchPerDiem));
			}

			if (item.useDinner && item.dinnerPerDiem > 0) {
				content.push(this.FormMSG(473, 'Dinner: ') + this.getCurrency(item.dinnerPerDiem));
			}

			if (item.useAbroad && item.abroadPerDiem > 0) {
				content.push(this.FormMSG(474, 'Abroad: ') + this.getCurrency(item.abroadPerDiem));
			}

			return content.join('<br />');
		},
		formatTime(value) {
			return moment(value).format('HH:mm');
		},
		rendAllowanceType(type) {
			if (type == 0) {
				return this.FormMSG(112, 'D');
			} else if (type == 1) {
				return this.FormMSG(113, 'W');
			} else if (type == 2) {
				return this.FormMSG(111, 'H');
			}
		},
		tooltipContentAllowance(item) {
			let content = [];
			if (item.carAllowance > 0) {
				content.push(
					this.FormMSG(1110, 'Car ') + '(' + this.rendAllowanceType(item.contract.carAllowanceRate) + '): ' + this.getCurrency(item.carAllowance)
				);
			}

			if (item.computerAllowance > 0) {
				content.push(
					this.FormMSG(1114, 'Computer ') +
						'(' +
						this.rendAllowanceType(item.contract.computerAllowanceRate) +
						'): ' +
						this.getCurrency(item.computerAllowance)
				);
			}

			if (item.phoneAllowance > 0) {
				content.push(
					this.FormMSG(1115, 'Phone ') +
						'(' +
						this.rendAllowanceType(item.contract.phoneAllowanceRate) +
						'): ' +
						this.getCurrency(item.phoneAllowance)
				);
			}

			if (item.productionFeeAllowance > 0) {
				content.push(
					this.FormMSG(1116, 'Production fee ') +
						'(' +
						this.rendAllowanceType(item.contract.productionFeeAllowanceRate) +
						'): ' +
						this.getCurrency(item.productionFeeAllowance)
				);
			}

			if (item.boxKitAllowance > 0) {
				content.push(
					this.FormMSG(1117, 'Box kit ') +
						'(' +
						this.rendAllowanceType(item.contract.boxKitAllowanceRate) +
						'): ' +
						this.getCurrency(item.boxKitAllowance)
				);
			}

			return content.join('<br />');
		}
	}
};
</script>
<style lang="scss" scoped>
.h-text-color {
	color: rgba(6, 38, 62, 0.84);
}
.name-title {
	font-size: 14px;
	font-weight: 500;
	color: #06263e;
}
</style>
