var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.useComponent
    ? _c("b-row", [
        _c("div", { staticClass: "container-layout" }, [
          _c("div", { staticClass: "p-0 card-collapsible" }, [
            _c(
              "div",
              { staticClass: "py-3" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                    on: {
                      click: function ($event) {
                        return _vm.handleClickHeader("show", _vm.Timesheets[0])
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "font-weight-bold fs-16 w-90" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.setFullTitle) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-end w-10" },
                      [
                        !_vm.isCollapsed
                          ? _c(_vm.getLucideIcon(_vm.ICONS.CHEVRON_DOWN.name), {
                              tag: "component",
                              attrs: { color: "#06263E", size: 22 },
                            })
                          : _vm._e(),
                        _vm.isCollapsed
                          ? _c(_vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name), {
                              tag: "component",
                              attrs: { color: "#06263E", size: 22 },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _c("b-collapse", { attrs: { visible: _vm.isCollapsed } }, [
                  _c(
                    "div",
                    { staticClass: "mt-3 px-2" },
                    [
                      _vm.useComponent
                        ? _c(
                            "b-row",
                            { attrs: { "align-h": "between" } },
                            [
                              _c(
                                "b-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isSubmittedStatus,
                                      expression: "isSubmittedStatus",
                                    },
                                  ],
                                  staticClass: "pb-2 ml-1",
                                  attrs: { md: "auto" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.notSumbmittedItem,
                                          expression: "notSumbmittedItem",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb",
                                          attrs: {
                                            size: "lg",
                                            value: true,
                                            "unchecked-value": false,
                                          },
                                          on: {
                                            change: _vm.selectAllNotSubmitted,
                                          },
                                          model: {
                                            value: _vm.notSumbmittedItem,
                                            callback: function ($$v) {
                                              _vm.notSumbmittedItem = $$v
                                            },
                                            expression: "notSumbmittedItem",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-top": "1px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(
                                                    13,
                                                    "Unselect all not submitted"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.notSumbmittedItem,
                                          expression: "!notSumbmittedItem",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb",
                                          attrs: {
                                            size: "lg",
                                            value: true,
                                            "unchecked-value": false,
                                          },
                                          on: {
                                            change: _vm.selectAllNotSubmitted,
                                          },
                                          model: {
                                            value: _vm.notSumbmittedItem,
                                            callback: function ($$v) {
                                              _vm.notSumbmittedItem = $$v
                                            },
                                            expression: "notSumbmittedItem",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-top": "1px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(
                                                    1,
                                                    "Select all not submitted"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c("b-col", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isSubmittedStatus,
                                    expression: "!isSubmittedStatus",
                                  },
                                ],
                                staticClass: "pb-2 ml-1",
                                attrs: { md: "auto" },
                              }),
                              _c(
                                "b-col",
                                {
                                  staticClass: "pb-2 ml-1",
                                  attrs: { md: "auto" },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "btn btn-primary pull-right block-sm",
                                      attrs: {
                                        disabled:
                                          _vm.itemsSelected.length === 0 &&
                                          _vm.itemIsNotSubmitted.length === 0,
                                      },
                                      on: { click: _vm.sendForValidation },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            this.FormMSG(
                                              2,
                                              "Send for validation"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("b-row", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row align-items-center mt-2 w-100",
                          },
                          [
                            _c(
                              "b-col",
                              { staticClass: "pb-3", attrs: { cols: "5" } },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder: this.FormMSG(
                                          3,
                                          "Type to Search"
                                        ),
                                      },
                                      model: {
                                        value: _vm.filter,
                                        callback: function ($$v) {
                                          _vm.filter = $$v
                                        },
                                        expression: "filter",
                                      },
                                    }),
                                    _c(
                                      "b-input-group-append",
                                      { staticClass: "cursor-pointer" },
                                      [
                                        _c(
                                          "b-input-group-text",
                                          { staticClass: "btn-search" },
                                          [
                                            _vm.filter.length === 0
                                              ? _c(
                                                  _vm.getLucideIcon(
                                                    _vm.ICONS.SEARCH.name
                                                  ),
                                                  {
                                                    tag: "component",
                                                    staticClass: "icon",
                                                    attrs: {
                                                      color:
                                                        _vm.ICONS.SEARCH.color,
                                                      size: 16,
                                                      "stroke-width": 2.5,
                                                    },
                                                  }
                                                )
                                              : _c(
                                                  _vm.getLucideIcon(
                                                    _vm.ICONS.X.name
                                                  ),
                                                  {
                                                    tag: "component",
                                                    staticClass: "icon",
                                                    attrs: {
                                                      color: _vm.ICONS.X.color,
                                                      size: 16,
                                                      "stroke-width": 2.5,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.filter = ""
                                                      },
                                                    },
                                                  }
                                                ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "7" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass:
                                          "d-flex align-items-center pb-3",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mr-3 text-color-australien",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "fs-14" },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.debutTotalPageNow()
                                                    ) +
                                                      " - " +
                                                      _vm._s(
                                                        _vm.totalPageNow()
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        this.FormMSG(25, "of")
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.totalAllPageRecords
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        this.FormMSG(
                                                          26,
                                                          "records"
                                                        )
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("b-col", { attrs: { cols: "6" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "float-right" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex w-100" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "mr-3 w-100-px",
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      options: _vm.pages,
                                                      label: "text",
                                                      reduce: (option) =>
                                                        option.value,
                                                      clearable: false,
                                                    },
                                                    on: {
                                                      "option:selected":
                                                        _vm.setPagesFunc,
                                                    },
                                                    model: {
                                                      value: _vm.setPage,
                                                      callback: function ($$v) {
                                                        _vm.setPage = $$v
                                                      },
                                                      expression: "setPage",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center mr-2 px-3",
                                                  attrs: {
                                                    variant: "primary",
                                                    size: "sm",
                                                    disabled:
                                                      _vm.debutTotalPageNow() ===
                                                        0 ||
                                                      _vm.disableButtonPrev,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.handleClickPrevious,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.SKIP_BACK.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.SKIP_BACK
                                                            .color,
                                                        size: 16,
                                                      },
                                                    }
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "ml-2",
                                                      staticStyle: {
                                                        "margin-top": "1px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            23,
                                                            "Prev"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center px-3",
                                                  attrs: {
                                                    variant: "primary",
                                                    size: "sm",
                                                    disabled:
                                                      _vm.totalPageNow() ===
                                                        _vm.totalAllPageRecords ||
                                                      _vm.disableButtonNext,
                                                  },
                                                  on: {
                                                    click: _vm.handleClickNext,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "mr-2",
                                                      staticStyle: {
                                                        "margin-top": "1px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            24,
                                                            "Next"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.SKIP_FORWARD
                                                        .name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.SKIP_FORWARD
                                                            .color,
                                                        size: 16,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "b-row",
                        { attrs: { "align-h": "between" } },
                        [
                          !_vm.isTimesheet
                            ? _c(
                                "b-col",
                                { staticClass: "pb-0", attrs: { cols: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "pj-cb pb-1",
                                          attrs: {
                                            id: `showGridDetails${_vm.i}`,
                                            size: "lg",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.showSalaryAction(
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.isShowSalaryDetail,
                                            callback: function ($$v) {
                                              _vm.isShowSalaryDetail = $$v
                                            },
                                            expression: "isShowSalaryDetail",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                this.FormMSG(
                                                  4,
                                                  "Show salary details"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.useComponent
                        ? _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12", xl: "12" } },
                                [
                                  _vm.$screen.md
                                    ? _c("b-table", {
                                        staticStyle: { "text-align": "center" },
                                        attrs: {
                                          hover: _vm.hover,
                                          responsive: "sm",
                                          filter: _vm.filter,
                                          items: _vm.dataTable,
                                          fields: _vm.Fields,
                                          "current-page":
                                            this.$props.currentpage,
                                          "per-page": this.$props.perpage,
                                          "empty-text": this.FormMSG(
                                            5,
                                            "No timesheet found"
                                          ),
                                          bordered: "",
                                          small: "",
                                          "sticky-header": "700px",
                                          "head-variant": this.$props.hv,
                                          "show-empty": "",
                                        },
                                        on: {
                                          "row-clicked":
                                            _vm.showDetailTimesheet,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "cell(select)",
                                              fn: function (data) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            data.item.status ===
                                                            0,
                                                          expression:
                                                            "data.item.status === 0",
                                                        },
                                                      ],
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        staticClass:
                                                          "pj-cb pb-1",
                                                        attrs: {
                                                          id: _vm.generateSecureId(
                                                            `timesheets-efc-selected-${
                                                              data.item.id +
                                                              Math.random()
                                                            }`
                                                          ),
                                                          name: _vm.generateSecureId(
                                                            `timesheetsEfcSelected${data.item.id}`
                                                          ),
                                                          size: "lg",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleChangeTimesheetsEfcSelected(
                                                              $event,
                                                              data.item
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            data.item
                                                              .isSelected,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              data.item,
                                                              "isSelected",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "data.item.isSelected",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(status)",
                                              fn: function (data) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "wrap-status",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          class: `status ${_vm.classStatus(
                                                            data.item.status
                                                          )}`,
                                                          staticStyle: {
                                                            "font-size":
                                                              "0.7rem",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.getLabelStatus(
                                                                  data.item
                                                                    .status
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(worked)",
                                              fn: function (data) {
                                                return [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.item.timesheet
                                                          .hours
                                                      ) +
                                                        ":" +
                                                        _vm._s(
                                                          data.item.timesheet
                                                            .minutes
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(encoder)",
                                              fn: function (data) {
                                                return [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.encodedBy(
                                                          data.item.timesheet
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(night)",
                                              fn: function (data) {
                                                return [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.item.timesheet
                                                          .hoursNight
                                                      ) +
                                                        ":" +
                                                        _vm._s(
                                                          data.item.timesheet
                                                            .minutesNight
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(transport)",
                                              fn: function (data) {
                                                return [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.item.timesheet
                                                          .hoursTransportTimePaid
                                                      ) +
                                                        ":" +
                                                        _vm._s(
                                                          data.item.timesheet
                                                            .minutesTransportTimePaid
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(anticipated)",
                                              fn: function (data) {
                                                return [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.item.timesheet
                                                          .hoursTooEarly
                                                      ) +
                                                        ":" +
                                                        _vm._s(
                                                          data.item.timesheet
                                                            .minutesTooEarly
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(lunch)",
                                              fn: function (data) {
                                                return [
                                                  _c("div", [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          data.item.timesheet
                                                            .weeklyLunchPerDiem
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(perDiem)",
                                              fn: function (data) {
                                                return [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatCurrency(
                                                          data.item.timesheet
                                                            .weeklyTotPerDiem
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(dailyOvt)",
                                              fn: function (data) {
                                                return [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.item.timesheet
                                                          .hoursOvertime
                                                      ) +
                                                        ":" +
                                                        _vm._s(
                                                          data.item.timesheet
                                                            .minutesOvertime
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(allowances)",
                                              fn: function (data) {
                                                return [
                                                  data.item.timesheet
                                                    .weeklyTotAllowances > 0
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.formatCurrency(
                                                                data.item
                                                                  .timesheet
                                                                  .weeklyTotAllowances
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(weeklyOvt)",
                                              fn: function (data) {
                                                return [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        data.item.timesheet
                                                          .weeklyTotalOvertimeStr
                                                      )
                                                    ),
                                                  ]),
                                                  _vm.isShowSalaryDetail
                                                    ? _c("div", [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatCurrency(
                                                                data.item
                                                                  .timesheet
                                                                  .weeklySalaryOvt
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatCurrency(
                                                                data.item
                                                                  .timesheet
                                                                  .weeklySalaryOvt1
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatCurrency(
                                                                data.item
                                                                  .timesheet
                                                                  .weeklySalaryOvt2
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(co2)",
                                              fn: function (data) {
                                                return [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatCo2(
                                                          data.item.co2
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(salary)",
                                              fn: function (data) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row align-items-center justify-content-end w-100",
                                                    },
                                                    [
                                                      _c("salary-log", {
                                                        attrs: {
                                                          item: data.item,
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "margin-top": "2px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.rendCurrency(
                                                                data.item.salary
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(remove)",
                                              fn: function (data) {
                                                return [
                                                  data.item.status === 0 ||
                                                  data.item.status === 4 ||
                                                  data.item.status === 16
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS.TRASH
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .TRASH
                                                                    .color,
                                                                size: 20,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    $event.preventDefault()
                                                                    return _vm.deleteActionTables(
                                                                      data.item,
                                                                      _vm.id
                                                                    )
                                                                  },
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3872807704
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _c("b-row", [
        _c(
          "div",
          { staticClass: "container-layout" },
          [
            _c("div", { staticClass: "p-0 card-collapsible" }, [
              _c(
                "div",
                { staticClass: "py-3" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "cursor-pointer header-card-collapsible d-flex flex-row align-items-center",
                      on: {
                        click: function ($event) {
                          return _vm.handleClickHeader(
                            "show",
                            _vm.Timesheets[0]
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "font-weight-bold fs-16 w-90" },
                        [_vm._v(_vm._s(_vm.setFullTitle))]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-end w-10" },
                        [
                          !_vm.isCollapsed
                            ? _c(
                                _vm.getLucideIcon(_vm.ICONS.CHEVRON_DOWN.name),
                                {
                                  tag: "component",
                                  attrs: { color: "#06263E", size: 22 },
                                }
                              )
                            : _vm._e(),
                          _vm.isCollapsed
                            ? _c(_vm.getLucideIcon(_vm.ICONS.CHEVRON_UP.name), {
                                tag: "component",
                                attrs: { color: "#06263E", size: 22 },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("b-collapse", { attrs: { visible: _vm.isCollapsed } }, [
                    _c(
                      "div",
                      { staticClass: "mt-3 px-2" },
                      [
                        _c(
                          "b-row",
                          { attrs: { "align-h": "between" } },
                          [
                            _c(
                              "b-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isSubmittedStatus,
                                    expression: "isSubmittedStatus",
                                  },
                                ],
                                staticClass: "pb-2 ml-1",
                                attrs: { md: "auto" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.notSumbmittedItem,
                                        expression: "notSumbmittedItem",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "pj-cb",
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        on: {
                                          change: _vm.selectAllNotSubmitted,
                                        },
                                        model: {
                                          value: _vm.notSumbmittedItem,
                                          callback: function ($$v) {
                                            _vm.notSumbmittedItem = $$v
                                          },
                                          expression: "notSumbmittedItem",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-top": "1px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                this.FormMSG(
                                                  13,
                                                  "Unselect all not submitted"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.notSumbmittedItem,
                                        expression: "!notSumbmittedItem",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "pj-cb",
                                        attrs: {
                                          size: "lg",
                                          value: true,
                                          "unchecked-value": false,
                                        },
                                        on: {
                                          change: _vm.selectAllNotSubmitted,
                                        },
                                        model: {
                                          value: _vm.notSumbmittedItem,
                                          callback: function ($$v) {
                                            _vm.notSumbmittedItem = $$v
                                          },
                                          expression: "notSumbmittedItem",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-top": "1px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                this.FormMSG(
                                                  1,
                                                  "Select all not submitted"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c("b-col", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isSubmittedStatus,
                                  expression: "!isSubmittedStatus",
                                },
                              ],
                              staticClass: "pb-2 ml-1",
                              attrs: { md: "auto" },
                            }),
                            _c(
                              "b-col",
                              {
                                staticClass: "pb-2 ml-1",
                                attrs: { md: "auto" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass:
                                      "btn btn-primary pull-right block-sm",
                                    attrs: {
                                      disabled:
                                        _vm.itemsSelected.length === 0 &&
                                        _vm.itemIsNotSubmitted.length === 0,
                                    },
                                    on: { click: _vm.sendForValidation },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          this.FormMSG(2, "Send for validation")
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("b-row", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row align-items-center mt-2 w-100",
                            },
                            [
                              _c(
                                "b-col",
                                { staticClass: "pb-3", attrs: { cols: "5" } },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "text",
                                          placeholder: "Type to Search",
                                        },
                                        model: {
                                          value: _vm.filter,
                                          callback: function ($$v) {
                                            _vm.filter = $$v
                                          },
                                          expression: "filter",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        { staticClass: "cursor-pointer" },
                                        [
                                          _c(
                                            "b-input-group-text",
                                            { staticClass: "btn-search" },
                                            [
                                              _vm.filter.length === 0
                                                ? _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.SEARCH.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      staticClass: "icon",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.SEARCH
                                                            .color,
                                                        size: 16,
                                                        "stroke-width": 2.5,
                                                      },
                                                    }
                                                  )
                                                : _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.X.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      staticClass: "icon",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.X.color,
                                                        size: 16,
                                                        "stroke-width": 2.5,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.filter = ""
                                                        },
                                                      },
                                                    }
                                                  ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "7" } },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "d-flex align-items-center pb-3",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mr-3 text-color-australien",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "fs-14" },
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.debutTotalPageNow()
                                                      ) +
                                                        " - " +
                                                        _vm._s(
                                                          _vm.totalPageNow()
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          this.FormMSG(25, "of")
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.totalAllPageRecords
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          this.FormMSG(
                                                            26,
                                                            "records"
                                                          )
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("b-col", { attrs: { cols: "6" } }, [
                                        _c(
                                          "div",
                                          { staticClass: "float-right" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "d-flex w-100" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mr-3 w-100-px",
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        options: _vm.pages,
                                                        label: "text",
                                                        reduce: (option) =>
                                                          option.value,
                                                        clearable: false,
                                                      },
                                                      on: {
                                                        "option:selected":
                                                          _vm.setPagesFunc,
                                                      },
                                                      model: {
                                                        value: _vm.setPage,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.setPage = $$v
                                                        },
                                                        expression: "setPage",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center mr-2 px-3",
                                                    attrs: {
                                                      variant: "primary",
                                                      size: "sm",
                                                      disabled:
                                                        _vm.debutTotalPageNow() ===
                                                          0 ||
                                                        _vm.disableButtonPrev,
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleClickPrevious,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        _vm.ICONS.SKIP_BACK.name
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color:
                                                            _vm.ICONS.SKIP_BACK
                                                              .color,
                                                          size: 16,
                                                        },
                                                      }
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "ml-2",
                                                        staticStyle: {
                                                          "margin-top": "1px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              23,
                                                              "Prev"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center px-3",
                                                    attrs: {
                                                      variant: "primary",
                                                      size: "sm",
                                                      disabled:
                                                        _vm.totalPageNow() ===
                                                          _vm.totalAllPageRecords ||
                                                        _vm.disableButtonNext,
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleClickNext,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "mr-2",
                                                        staticStyle: {
                                                          "margin-top": "1px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              24,
                                                              "Next"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        _vm.ICONS.SKIP_FORWARD
                                                          .name
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color:
                                                            _vm.ICONS
                                                              .SKIP_FORWARD
                                                              .color,
                                                          size: 16,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "b-row",
                          { attrs: { "align-h": "between" } },
                          [
                            !_vm.isTimesheet
                              ? _c(
                                  "b-col",
                                  { staticClass: "pb-0", attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: {
                                              id: `showGridDetails${_vm.i}`,
                                              size: "lg",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.showSalaryAction(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.isShowSalaryDetail,
                                              callback: function ($$v) {
                                                _vm.isShowSalaryDetail = $$v
                                              },
                                              expression: "isShowSalaryDetail",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  this.FormMSG(
                                                    6,
                                                    "Show salary details"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12", xl: "12" } },
                              [
                                _vm.$screen.md
                                  ? _c("b-table", {
                                      staticStyle: { "text-align": "center" },
                                      attrs: {
                                        filter: _vm.filter,
                                        hover: _vm.hover,
                                        responsive: "sm",
                                        items: _vm.dataTable,
                                        fields: _vm.Fields,
                                        "current-page": this.$props.currentpage,
                                        "per-page": this.$props.perpage,
                                        "empty-text": this.FormMSG(
                                          7,
                                          "No timesheet found"
                                        ),
                                        bordered: "",
                                        small: "",
                                        "sticky-header": "700px",
                                        "head-variant": this.$props.hv,
                                        "show-empty": "",
                                      },
                                      on: { "row-clicked": _vm.rowClicked },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "cell(select)",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          data.item.status ===
                                                          0,
                                                        expression:
                                                          "data.item.status === 0",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _c("b-form-checkbox", {
                                                      staticClass: "pj-cb pb-1",
                                                      attrs: {
                                                        id: _vm.generateSecureId(
                                                          `timesheets-efc-selected-${
                                                            data.item.id +
                                                            Math.random()
                                                          }`
                                                        ),
                                                        name: _vm.generateSecureId(
                                                          `timesheetsEfcSelected${data.item.id}`
                                                        ),
                                                        size: "lg",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleChangeTimesheetsEfcSelected(
                                                            $event,
                                                            data.item
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          data.item.isSelected,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            data.item,
                                                            "isSelected",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.item.isSelected",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(crew_member)",
                                            fn: function (data) {
                                              return [
                                                _c("div", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        data.item.crew_member
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(status)",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "wrap-status",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        class: `status ${_vm.classStatus(
                                                          data.item.status
                                                        )}`,
                                                        staticStyle: {
                                                          "font-size": "0.7rem",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.getLabelStatus(
                                                                data.item.status
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(worked)",
                                            fn: function (data) {
                                              return [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.setWorked(
                                                        data.item.tsDay.hours,
                                                        data.item.tsDay.minutes
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(encoder)",
                                            fn: function (data) {
                                              return [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.encodedBy(
                                                        data.item.tsDay
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(dayType)",
                                            fn: function (data) {
                                              return [
                                                _c("div", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.getFieldValueByShowGridDetails(
                                                          _vm.showGridDetails,
                                                          data.item.tsDay
                                                            .dayType,
                                                          data.item.tsDay
                                                            .salaryBase
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]),
                                                _vm.isShowSalaryDetail
                                                  ? _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatCurrency(
                                                            data.item.tsDay
                                                              .salaryBase
                                                          )
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(ovt)",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row justify-content-center",
                                                    staticStyle: { gap: "4px" },
                                                  },
                                                  [
                                                    _vm.setTotalOvt(
                                                      [
                                                        data.item.tsDay
                                                          .hoursOvertime,
                                                        data.item.tsDay
                                                          .hoursOvertime1,
                                                        data.item.tsDay
                                                          .hoursOvertime2,
                                                      ],
                                                      [
                                                        data.item.tsDay
                                                          .minutesOvertime,
                                                        data.item.tsDay
                                                          .minutesOvertime1,
                                                        data.item.tsDay
                                                          .minutesOvertime2,
                                                      ]
                                                    ) !== "00:00"
                                                      ? _c(
                                                          "button",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "b-tooltip",
                                                                rawName:
                                                                  "v-b-tooltip.hover.left.html",
                                                                value:
                                                                  _vm.tooltipContentOvertime(
                                                                    data.item
                                                                      .tsDay
                                                                  ),
                                                                expression:
                                                                  "tooltipContentOvertime(data.item.tsDay)",
                                                                modifiers: {
                                                                  hover: true,
                                                                  left: true,
                                                                  html: true,
                                                                },
                                                              },
                                                            ],
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                _vm.ICONS.INFO
                                                                  .name
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    _vm.ICONS
                                                                      .INFO
                                                                      .color,
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c("div", [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.setTotalOvt(
                                                              [
                                                                data.item.tsDay
                                                                  .hoursOvertime,
                                                                data.item.tsDay
                                                                  .hoursOvertime1,
                                                                data.item.tsDay
                                                                  .hoursOvertime2,
                                                              ],
                                                              [
                                                                data.item.tsDay
                                                                  .minutesOvertime,
                                                                data.item.tsDay
                                                                  .minutesOvertime1,
                                                                data.item.tsDay
                                                                  .minutesOvertime2,
                                                              ]
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm.isShowSalaryDetail
                                                  ? _c("div", [
                                                      _c("div", [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.setSommeOvt(
                                                                data.item.tsDay
                                                                  .salaryOvertime,
                                                                data.item.tsDay
                                                                  .salaryOvertime1,
                                                                data.item.tsDay
                                                                  .salaryOvertime2
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(lunch)",
                                            fn: function (data) {
                                              return [
                                                _c("div", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        !data.item.tsDay
                                                          .notSpecifiedLunch
                                                          ? `${_vm.convertTime(
                                                              data.item.tsDay
                                                                .lunchStrTime
                                                            )} - ${_vm.convertTime(
                                                              data.item.tsDay
                                                                .lunchEndTime
                                                            )}`
                                                          : "-"
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(night)",
                                            fn: function (data) {
                                              return [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      data.item.tsDay.hoursNight
                                                        .toString()
                                                        .padStart("2", "0")
                                                    ) +
                                                      ":" +
                                                      _vm._s(
                                                        data.item.tsDay
                                                          .minutesNight
                                                      )
                                                  ),
                                                ]),
                                                _vm.isShowSalaryDetail
                                                  ? _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatCurrency(
                                                            data.item.tsDay
                                                              .salaryHourNight
                                                          )
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(anticipated)",
                                            fn: function (data) {
                                              return [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      data.item.tsDay.hoursTooEarly
                                                        .toString()
                                                        .padStart("2", "0")
                                                    ) +
                                                      ":" +
                                                      _vm._s(
                                                        data.item.tsDay
                                                          .minutesTooEarly
                                                      )
                                                  ),
                                                ]),
                                                _vm.isShowSalaryDetail
                                                  ? _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatCurrency(
                                                            data.item.tsDay
                                                              .salaryHourTooEarly
                                                          )
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                          _vm.showLunchPerDiem ||
                                          _vm.showHotelPerDiem ||
                                          _vm.showDinnerPerDiem ||
                                          _vm.showAbroadPerDiem
                                            ? {
                                                key: "cell(totPerDiem)",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-row justify-content-center",
                                                        staticStyle: {
                                                          gap: "4px",
                                                        },
                                                      },
                                                      [
                                                        data.item.tsDay
                                                          .totPerDiem > 0
                                                          ? _c(
                                                              "button",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "b-tooltip",
                                                                    rawName:
                                                                      "v-b-tooltip.hover.left.html",
                                                                    value:
                                                                      _vm.tooltipContentPerIdem(
                                                                        data
                                                                          .item
                                                                          .tsDay
                                                                      ),
                                                                    expression:
                                                                      "tooltipContentPerIdem(data.item.tsDay)",
                                                                    modifiers: {
                                                                      hover: true,
                                                                      left: true,
                                                                      html: true,
                                                                    },
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "btn-transparent text-color-rhapsody-in-blue",
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getLucideIcon(
                                                                    _vm.ICONS
                                                                      .INFO.name
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      color:
                                                                        _vm
                                                                          .ICONS
                                                                          .INFO
                                                                          .color,
                                                                      size: 16,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatCurrency(
                                                                data.item.tsDay
                                                                  .totPerDiem
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              }
                                            : null,
                                          {
                                            key: "cell(dayRate)",
                                            fn: function (data) {
                                              return [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      data.item.tsDay.dayRate
                                                    ) + "%"
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(transport)",
                                            fn: function (data) {
                                              return [
                                                _c("div", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        data.item.tsDay.hoursTransportTimePaid
                                                          .toString()
                                                          .padStart("2", "0")
                                                      ) +
                                                      ":" +
                                                      _vm._s(
                                                        data.item.tsDay
                                                          .minutesTransportTimePaid
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]),
                                                _vm.isShowSalaryDetail
                                                  ? _c("div", [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatCurrency(
                                                              data.item.tsDay
                                                                .salaryTransport
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(co2)",
                                            fn: function (data) {
                                              return [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatCo2(
                                                        data.item.tsDay.kgCoTwo
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(allowances)",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row justify-content-center",
                                                    staticStyle: { gap: "4px" },
                                                  },
                                                  [
                                                    data.item.tsDay
                                                      .totAllowances > 0
                                                      ? _c(
                                                          "button",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "b-tooltip",
                                                                rawName:
                                                                  "v-b-tooltip.hover.left.html",
                                                                value:
                                                                  _vm.tooltipContentAllowance(
                                                                    data.item
                                                                      .tsDay
                                                                  ),
                                                                expression:
                                                                  "tooltipContentAllowance(data.item.tsDay)",
                                                                modifiers: {
                                                                  hover: true,
                                                                  left: true,
                                                                  html: true,
                                                                },
                                                              },
                                                            ],
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                _vm.ICONS.INFO
                                                                  .name
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    _vm.ICONS
                                                                      .INFO
                                                                      .color,
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatCurrency(
                                                            data.item.tsDay
                                                              .totAllowances
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(salary)",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row align-items-center justify-content-end w-100",
                                                  },
                                                  [
                                                    _c("salary-log", {
                                                      attrs: {
                                                        item: data.item,
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "2px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.rendCurrency(
                                                              data.item.salary
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(remove)",
                                            fn: function (data) {
                                              return [
                                                data.item.status === 0 ||
                                                data.item.status === 4 ||
                                                data.item.status === 16
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS.TRASH.name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS.TRASH
                                                                  .color,
                                                              size: 20,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                $event.preventDefault()
                                                                return _vm.deleteActionTables(
                                                                  data.item,
                                                                  _vm.id
                                                                )
                                                              },
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _c("time-card-for-crew-modal", {
              attrs: {
                update: _vm.update,
                editData: _vm.editData,
                isOpen: _vm.Open,
                showModal: _vm.showModal,
                hideModal: _vm.hideModal,
                isActionCrud: _vm.isActionCrud,
                addDataFunc: (e) => _vm.addDataFunc(e),
                refreshDataItem: (item) => _vm.refreshDataItem(item),
                screenUsedTheForm: `entry-for-crew`,
                useFormInModal: _vm.useFormInModal,
              },
              on: {
                "add:Submitted": _vm.handleRefreshData,
                closeModal: _vm.closeModal,
              },
            }),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }