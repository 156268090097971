var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "clearfix mb-2" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "h1",
              {
                class: [
                  `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                  { "is-pwa": _vm.$isPwa() },
                ],
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(77, "Timesheets - Entry for crew")) +
                    "\n\t\t\t"
                ),
              ]
            ),
          ]),
          _c(
            "b-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn btn-success pull-right block-sm",
                  on: {
                    click: function ($event) {
                      return _vm.showModal()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.FormMSG(3, "Add to timesheet")) +
                      "\n\t\t\t"
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("time-card-for-crew-modal", {
            attrs: {
              editData: _vm.editData,
              isOpen: _vm.Open,
              showModal: _vm.showModal,
              hideModal: _vm.hideModal,
              isActionCrud: _vm.isActionCrud,
              addDataFunc: _vm.addDataFunc,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }