<style></style>
<template>
	<div class="d-flex flex-row justify-content-center align-items-center p-2" style="background-color: #f3f4f5">
		<div class="d-flex flex-row justify-content-end ml-1 col-2">
			<b-button variant="light" @click="firstDate()">
				<component :is="getLucideIcon(ICONS.CHEVRON_FIRST_MONTH.name)" :color="ICONS.CHEVRON_FIRST_MONTH.color" :size="22" />
			</b-button>
		</div>
		<div class="d-flex flex-row justify-content-end ml-1 col-2">
			<b-button variant="light" @click="prevDate()">
				<component :is="getLucideIcon(ICONS.SKIP_BACK_MONTH.name)" :color="ICONS.SKIP_BACK_MONTH.color" :size="22" />
			</b-button>
		</div>
		<div class="d-flex flex-column align-items-center justify-content-center ml-1 col-4">
			<div>
				<b-button variant="light" @click="showCardDate">
					{{ DateDefaultSelect.text.charAt(0).toUpperCase() + DateDefaultSelect.text.substr(1) }}
				</b-button>
			</div>
			<div class="card-container card-list-date" v-show="isDateSelect" ref="showListDate">
				<div class="card">
					<div class="card-title">
						<div class="d-flex justify-content-start" style="width: 80px">
							<b-button variant="light" @click="prevChooseDate()">
								<component :is="getLucideIcon(ICONS.SKIP_BACK_MONTH.name)" :color="ICONS.SKIP_BACK_MONTH.color" :size="12" />
							</b-button>
						</div>
						<div class="d-flex flex-row justify-content-center align-items-center" style="width: 100px">
							{{ setYearChoose }}
						</div>
						<div class="d-flex justify-content-end" style="width: 80px">
							<b-button variant="light" @click="nextChooseDate()">
								<component :is="getLucideIcon(ICONS.SKIP_FORWARD_MONTH.name)" :color="ICONS.SKIP_FORWARD_MONTH.color" :size="12" />
							</b-button>
						</div>
					</div>
					<div class="card-description">
						<div class="month-item">
							<div
								v-for="item of months"
								@key="item"
								class="month-hover"
								@click="setDateCard(item)"
								:id="generateSecureId(`${item.text}${item.value}`)"
							>
								{{ item.text }}
							</div>
						</div>
					</div>
					<div class="btn-action-select-date">
						<button @click="closeSelectDate">{{ this.FormMSG(1, 'Close') }}</button>
						<button @click="validateDate">{{ this.FormMSG(2, 'Ok') }}</button>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex flex-row justify-content-start -1 col-2">
			<b-button variant="light" @click="nextDate()">
				<component :is="getLucideIcon(ICONS.SKIP_FORWARD_MONTH.name)" :color="ICONS.SKIP_FORWARD_MONTH.color" :size="22" />
			</b-button>
		</div>
		<div class="d-flex flex-row justify-content-start ml-1 col-2">
			<b-button variant="light" @click="lastDate()">
				<component :is="getLucideIcon(ICONS.CHEVRON_LAST.name)" :color="ICONS.CHEVRON_LAST.color" :size="22" />
			</b-button>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { generateSecureId } from '@/shared/utils';
import moment from 'moment';
export default {
	name: 'MonthSelect',
	components: {},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		DateDefaultSelect: {
			type: Object,
			required: false,
			immediate: true
		}
	},
	data: () => {
		return {
			generateSecureId,
			month: 1,
			year: 2019,
			isDateSelect: false,
			clickedOutside: false,
			yearChoose: new Date().getFullYear(),
			months: [],
			actionDatesCards: [],
			secureIdPersitent: ''
		};
	},
	mounted() {
		document.addEventListener('click', this.handleClickOutside);
	},
	destroyed() {
		document.removeEventListener('click', this.handleClickOutside);
	},

	watch: {
		isDateSelect: {
			handler(newVal) {
				if (newVal === false) {
					if (this.actionDatesCards.length !== 0) {
						let id = this.actionDatesCards[0].securedId;
						document.getElementById(id).style.backgroundColor = '#f8f9fa';
					}
					if (this.secureIdPersitent.length !== 0) {
						document.getElementById(this.secureIdPersitent).style.backgroundColor = '#f8f9fa';
					}
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		yearChoose: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	created() {},
	computed: {
		setYearChoose() {
			return this.yearChoose;
		}
	},
	methods: {
		nextDate() {
			const langCurrent = this.currentLang;
			let _date = this.DateDefaultSelect.value.split`/`.map((x) => +x);
			this.month = ++_date[0];
			this.year = _date[1];
			if (this.month > 12) {
				let restMonth = this.month % 12;
				this.month = restMonth;
				this.year = ++_date[1];
			}
			let value = this.month.toString().padStart('2', '0') + '/' + this.year;
			let setDefaultDate = {
				value: value,
				text: moment(value, 'MM/YYYY').locale(`${langCurrent}`).format('MMMM YYYY')
			};
			this.$emit('setDateDefaultSelect', setDefaultDate);
		},
		prevDate() {
			const langCurrent = this.currentLang;
			let _date = this.DateDefaultSelect.value.split`/`.map((x) => +x);
			this.month = --_date[0];
			this.year = _date[1];
			if (this.month < 1) {
				this.month = 12;
				this.year = --_date[1];
			}
			let value = this.month.toString().padStart('2', '0') + '/' + this.year;
			let setDefaultDate = {
				value: value,
				text: moment(value, 'MM/YYYY').locale(`${langCurrent}`).format('MMMM YYYY')
			};
			this.$emit('setDateDefaultSelect', setDefaultDate);
		},
		firstDate() {
			const langCurrent = this.currentLang;
			let _date = this.DateDefaultSelect.value.split`/`.map((x) => +x);
			this.month = _date[0];
			this.year = --_date[1];
			let value = this.month.toString().padStart('2', '0') + '/' + this.year;
			let setDefaultDate = {
				value: value,
				text: moment(value, 'MM/YYYY').locale(`${langCurrent}`).format('MMMM YYYY')
			};
			this.$emit('setDateDefaultSelect', setDefaultDate);
		},
		lastDate() {
			const langCurrent = this.currentLang;
			let _date = this.DateDefaultSelect.value.split`/`.map((x) => +x);
			this.month = _date[0];
			this.year = ++_date[1];
			let value = this.month.toString().padStart('2', '0') + '/' + this.year;
			let setDefaultDate = {
				value: value,
				text: moment(value, 'MM/YYYY').locale(`${langCurrent}`).format('MMMM YYYY')
			};
			this.$emit('setDateDefaultSelect', setDefaultDate);
		},
		showCardDate() {
			let initUiid = null;
			if (this.actionDatesCards.length !== 0) {
				initUiid = this.actionDatesCards[0].securedId;
			}
			const langCurrent = this.currentLang;
			this.isDateSelect = !this.isDateSelect;
			this.yearChoose = +this.DateDefaultSelect.value.split`/`[1];
			let text = moment(this.DateDefaultSelect.value, 'MM/YYYY').locale(`${langCurrent}`).format('MMM');
			let value = this.DateDefaultSelect.value;
			let uiid = this.generateSecureId(`${text}${value}`);
			this.actionDatesCards = [
				{
					securedId: uiid,
					data: {
						text: text,
						value: value
					}
				}
			];
			this.months = Array.from({ length: 12 }, (v, i) => {
				const value = (++i).toString().padStart('2', '0') + '/' + this.yearChoose;
				const monthName = moment()
					.locale(`${langCurrent}`)
					.month(i - 1)
					.format('MMM');
				return { value: value, text: monthName };
			});
			if (this.isDateSelect) {
				setTimeout(() => {
					if (initUiid !== null && initUiid !== undefined) {
						document.getElementById(initUiid).style.backgroundColor = '#f8f9fa';
					}
					document.getElementById(uiid).style.backgroundColor = '#47c7bf';
					this.secureIdPersitent = this.actionDatesCards[0].securedId;
				}, 100);
			}
		},
		setDateCard(item) {
			let id = this.generateSecureId(`${item.text}${item.value}`);
			if (this.actionDatesCards.length === 0) {
				document.getElementById(id).style.backgroundColor = '#47c7bf';
				this.actionDatesCards = [{ securedId: id, data: item }];
				this.secureIdPersitent = this.actionDatesCards[0].securedId;
				this.actionDatesCards = this.actionDatesCards.map((item) => {
					return { ...item, data: { ...item.data, value: item.data.value.split`/`[0] + '/' + this.yearChoose } };
				});
			} else {
				let isFind = this.actionDatesCards.find((e) => e.securedId === id);
				if (isFind !== undefined) {
					document.getElementById(id).style.backgroundColor = '#f8f9fa';
					this.actionDatesCards = this.actionDatesCards.filter((item) => item.securedId !== id);
				} else {
					document.getElementById(this.secureIdPersitent).style.backgroundColor = '#f8f9fa';
					document.getElementById(id).style.backgroundColor = '#47c7bf';
					this.actionDatesCards = [{ securedId: id, data: item }];
					this.secureIdPersitent = this.actionDatesCards[0].securedId;
					this.actionDatesCards = this.actionDatesCards.map((item) => {
						return { ...item, data: { ...item.data, value: item.data.value.split`/`[0] + '/' + this.yearChoose } };
					});
				}
			}
		},
		nextChooseDate() {
			this.yearChoose = ++this.yearChoose;
		},
		prevChooseDate() {
			this.yearChoose = --this.yearChoose;
		},
		closeSelectDate() {
			this.isDateSelect = false;
			this.clickedOutside = false;
			if (this.actionDatesCards.length !== 0) {
				document.getElementById(this.secureIdPersitent).style.backgroundColor = '#f8f9fa';
				this.actionDatesCards = [];
			}
			this.yearChoose = +this.DateDefaultSelect.value.split`/`[1];
		},
		validateDate() {
			this.isDateSelect = false;
			this.clickedOutside = false;
			const langCurrent = this.currentLang;
			if (this.actionDatesCards.length !== 0) {
				document.getElementById(this.secureIdPersitent).style.backgroundColor = '#f8f9fa';
				this.actionDatesCards = this.actionDatesCards.map((item) => {
					return { ...item, data: { ...item.data, value: item.data.value.split`/`[0] + '/' + this.yearChoose } };
				});
				let validateDateSelected = {
					text: moment(this.actionDatesCards[0].data.value, 'MM/YYYY').locale(`${langCurrent}`).format('MMMM YYYY'),
					value: this.actionDatesCards[0].data.value
				};
				this.$emit('setDateDefaultSelect', validateDateSelected);
			}
			this.actionDatesCards = [];
			this.yearChoose = +this.DateDefaultSelect.value.split`/`[1];
		},
		handleClickOutside(event) {
			if (this.isDateSelect) {
				if (!this.$refs.showListDate.contains(event.target)) {
					if (this.clickedOutside === false) {
						this.clickedOutside = true;
					} else {
						this.clickedOutside = false;
						this.isDateSelect = false;
					}
				}
			} else {
				this.isDateSelect = false;
				this.clickedOutside = false;
			}
		}
	}
};
</script>
<style scoped>
.card-list-date {
	position: absolute;
	top: 100%;
	z-index: 999;
}

.card-container {
	width: 100%;
	max-width: 20rem;
	margin: 0 auto;
}

/* Card */
.card {
	background-color: #f8f9fa;
	border-radius: 8px;
	padding: 20px;
}

/* Card title */
.card-title {
	display: flex;
	justify-content: center;
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 10px;
}

/* Card description */
.card-description {
	font-size: 12px;
	margin-bottom: 20px;
}

/* Card footer */
.card-footer {
	font-size: 12px;
}
.btn-action-select-date {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 10px;
	color: #47c7bf;
	outline: none;
}
.btn-action-select-date button {
	border: none;
	outline: none;
	background-color: #f8f9fa;
	font-weight: bold;
	color: #47c7bf;
}
.btn-action-select-date button:hover {
	font-weight: bold;
	color: #00a09c;
}
.month-item {
	display: flex;
	justify-content: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 40px;
	width: auto;
}
.month-hover {
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.month-hover:hover {
	font-weight: bold;
	background: #47c7bf;
	cursor: pointer;
}
/* Responsive styles */
@media screen and (max-width: 20rem) {
	.card-title {
		font-size: 20px;
	}
	.card-description {
		font-size: 14px;
	}
	.card-footer {
		font-size: 12px;
	}
}
</style>
