<style></style>
<template>
	<div class="clearfix mb-0">
		<b-row class="pb-1 pt-0 pl-4">
			<b-form-radio-group v-model="setGroupByFilter" class="mb-3" text-field="name" @change="(item) => setFilter(item)">
				<b-form-radio v-show="id === timecards_One" :value="planningView">
					<div class="mr-4" style="margin-top: 1px">{{ this.FormMSG(9, 'Planning view') }}</div>
				</b-form-radio>
				<b-form-radio :value="groupByDate">
					<div class="mr-4" style="margin-top: 1px">{{ this.FormMSG(2, 'Per date') }}</div>
				</b-form-radio>
				<b-form-radio :value="groupByPerson">
					<div class="mr-4" style="margin-top: 1px">{{ this.FormMSG(1, 'Per person') }}</div>
				</b-form-radio>
				<b-form-radio :value="groupByStatus">
					<div class="mr-4" style="margin-top: 1px">{{ this.FormMSG(3, 'Per status') }}</div>
				</b-form-radio>
			</b-form-radio-group>
		</b-row>
		<div v-if="setGroupByFilter === planningView">
			<b-row align-h="between">
				<b-col class="pb-0 ml-1">
					<b-input-group>
						<b-form-input
							v-model.trim="filter"
							type="text"
							:placeholder="this.FormMSG(11, 'Type to search...')"
							id="filterInput"
							@keyup.enter="onSearch(filter, notReset, id)"
							autocomplete="off"
							v-b-tooltip.focus
						/>
						<b-input-group-append class="cursor-pointer">
							<b-input-group-text class="btn-search">
								<component
									:is="getLucideIcon(ICONS.SEARCH.name)"
									:color="ICONS.SEARCH.color"
									:size="16"
									class="icon"
									:stroke-width="2.5"
									v-if="filter.length === 0"
								/>
								<component
									:is="getLucideIcon(ICONS.X.name)"
									:color="ICONS.X.color"
									:size="16"
									class="icon"
									:stroke-width="2.5"
									@click="() => initReset()"
									v-else
								/>
							</b-input-group-text>
							<b-tooltip target="filterInput" placement="top" triggers="focus">
								{{ this.FormMSG(12, "Type 'Enter' to launch search") }}
							</b-tooltip>
						</b-input-group-append>
					</b-input-group>
				</b-col>
				<b-col class="pb-0 ml-1">
					<b-form-group>
						<v-select
							class="w-100"
							v-model="department"
							placeholder="Select departement"
							:options="allDepartements"
							label="message"
							:clearable="true"
							@option:selected="handleSelectDepartement"
							:reduce="(option) => option.value"
						/>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row align-h="between">
				<b-col cols="7" class="pb-2 ml-1">
					<div class="d-flex flex-row" style="gap: 10px">
						<div class="d-flex flex-row justify-content-start align-items-center gp-5">
							<div class="status-not-submitted"></div>
							<div class="col-form-label">{{ FormMSG(18, 'Not submitted') }}</div>
						</div>
						<div class="d-flex flex-row justify-content-start align-items-center gp-5">
							<div class="status-info"></div>
							<div>{{ FormMSG(13, 'Submitted') }}</div>
						</div>
						<div class="d-flex flex-row justify-content-start align-items-center gp-5">
							<div class="status-pending"></div>
							<div class="col-form-label">{{ FormMSG(19, 'Validated HOD') }}</div>
						</div>
						<div class="d-flex flex-row justify-content-start align-items-center gp-5">
							<div class="status-validated"></div>
							<div class="col-form-label">{{ FormMSG(15, 'Validated') }}</div>
						</div>
						<div class="d-flex flex-row justify-content-start align-items-center gp-5">
							<div class="status-refused"></div>
							<div class="col-form-label">{{ FormMSG(16, 'Refused') }}</div>
						</div>
					</div>
				</b-col>
				<b-col cols="4" class="d-flex flex-row justify-content-end pb-2 w-100 gap-1">
					<div class="d-flex justify-content-end w-auto">
						<b-button @click="onOpenModalToAllDelete" variant="danger" :disabled="countDataToUpdate(dataPlanningSelect) === '(00)' ? true : false"
							>{{ FormMSG(20, 'Delete all not submitted ') }} {{ countDataToUpdate(dataPlanningSelect) }}</b-button
						>
					</div>
					<div class="d-flex justify-content-end w-auto">
						<b-button :disabled="dataPlanningSelect === undefined ? true : dataPlanningSelect.length === 0 ? true : false" @click="showModal">{{
							FormMSG(10, 'Add Selected Planning')
						}}</b-button>
					</div>
				</b-col>
			</b-row>
		</div>
		<div v-else>
			<b-row align-h="between">
				<b-col cols="6" class="pb-3 ml-1" v-if="!useComponent && groupByFilter === groupByPerson">
					<b-input-group>
						<b-form-input
							v-model.trim="filter"
							type="text"
							id="filterInput"
							placeholder="Type to Search"
							@keyup.enter="onSearch(filter, notReset, id)"
							v-b-tooltip.focus
						/>
						<b-input-group-append class="cursor-pointer">
							<b-input-group-text class="btn-search">
								<component
									:is="getLucideIcon(ICONS.SEARCH.name)"
									:color="ICONS.SEARCH.color"
									:size="16"
									class="icon"
									:stroke-width="2.5"
									v-if="filter.length === 0"
								/>
								<component
									:is="getLucideIcon(ICONS.X.name)"
									:color="ICONS.X.color"
									:size="16"
									class="icon"
									:stroke-width="2.5"
									@click="() => initReset()"
									v-else
								/>
							</b-input-group-text>
							<b-tooltip target="filterInput" placement="top" triggers="focus">
								{{ this.FormMSG(12, "Type 'Enter' to launch search") }}
							</b-tooltip>
						</b-input-group-append>
					</b-input-group>
				</b-col>
				<b-col cols="6" class="pb-3 ml-1" v-if="useComponent && groupByFilter === groupByPerson">
					<b-input-group>
						<b-form-input
							v-model.trim="filter"
							type="text"
							id="filterInput-timesheets-two"
							:placeholder="this.FormMSG(4, 'Type to Search')"
							@keyup.enter="onSearch(filter, notReset, id)"
							v-b-tooltip.focus
						/>
						<b-input-group-append class="cursor-pointer">
							<b-input-group-text class="btn-search">
								<component
									:is="getLucideIcon(ICONS.SEARCH.name)"
									:color="ICONS.SEARCH.color"
									:size="16"
									class="icon"
									:stroke-width="2.5"
									v-if="filter.length === 0"
								/>
								<component
									:is="getLucideIcon(ICONS.X.name)"
									:color="ICONS.X.color"
									:size="16"
									class="icon"
									:stroke-width="2.5"
									@click="() => initReset()"
									v-else
								/>
							</b-input-group-text>
							<b-tooltip target="filterInput-timesheets-two" placement="top" triggers="focus">
								{{ this.FormMSG(12, "Type 'Enter' to launch search") }}
							</b-tooltip>
						</b-input-group-append>
					</b-input-group>
				</b-col>
				<b-col cols="6" class="pb-3 ml-1" v-if="useComponent && groupByFilter === groupByDate">
					<!-- :reduce="(option) => option.value" -->
					<b-form-group>
						<v-select v-model="currentForm" :clearable="false" label="text" :options="optionsMonth" @option:selected="(e) => selecteMonth(e, id)" />
					</b-form-group>
				</b-col>
				<b-col cols="12" class="pb-3 ml-1" v-if="!useComponent && groupByFilter === groupByDate">
					<!-- :reduce="(option) => option.value" -->
					<b-row>
						<b-col cols="6"
							><b-form-group>
								<v-select
									v-model="currentForm"
									:clearable="currentForm.value === dateNow.value ? false : true"
									label="text"
									:options="MonthList"
									@option:selected="(e) => selecteMonth(e, id)"
								/> </b-form-group
						></b-col>
						<b-col cols="6">
							<b-form-group>
								<v-select
									v-model="currentDay"
									:clearable="false"
									@option:selected="(e) => handleSelectedDay(e)"
									label="text"
									:options="flattenDays"
								/>
							</b-form-group>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<b-row align-h="between" v-if="!useComponent && groupByFilter !== groupByDate">
				<b-col md="6" class="pl-4">
					<p class="fw-400" style="font-size: 13px; color: rgba(6, 38, 62, 0.65)">
						{{ this.FormMSG(8, '* Click on a row to view more informations or edit') }}
					</p>
				</b-col>
			</b-row>
		</div>
		<b-modal
			v-if="openModalDeleteAll"
			v-model="openModalDeleteAll"
			@hidden="handleCloseAllDeleteModal"
			no-close-on-backdrop
			:title="FormMSG(21, 'Do you want to remove these timesheets')"
		>
			<div class="d-flex flex-column gap-1">
				<div class="d-flex flex-row align-items-center w-100 border-rd p-2" v-for="(item, index) of groupByData()" :key="index">
					<div class="w-50">
						<label>{{ item.fullName }}</label>
					</div>
					<div class="d-flex flex-column border-lf w-50">
						<div class="text-1" v-for="(children, index) of item.data" :key="index">{{ children.date }}</div>
					</div>
				</div>
			</div>

			<template #modal-footer>
				<div class="d-flex flex-row justify-content-end w-100 gap-1 w-1">
					<div>
						<b-button variant="outline-primary" size="sm" class="border-1" @click="handleCloseAllDeleteModal"> {{ FormMSG(22, 'No') }} </b-button>
					</div>
					<div>
						<b-button variant="outline-danger" size="sm" class="border-1" @click="handleDeleteAllPlanning"> {{ FormMSG(23, 'Yes') }} </b-button>
					</div>
				</div>
			</template>
		</b-modal>
	</div>
</template>
<script>
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { FILTER_STRING_TIME_SHEET, NAME_CARDS_TIMESHEET_EFC } from '@/utils/utils';
import { getDepartments } from '@/cruds/department.crud';
import { deleteTimeCardsTsDay } from '@/cruds/timesheets-efc.crud';
import { GetEncodingValidation } from '@/cruds/users.crud';
import moment from 'moment';
export default {
	name: 'HeaderTimesheets',
	components: {},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		useComponent: {
			type: Boolean,
			required: false
		},
		id: {
			type: String,
			required: false
		},
		setFilter: {
			type: Function,
			required: false
		},
		setPages: {
			type: Function,
			required: false
		},
		groupByFilter: {
			type: String,
			required: false
		},
		onSearch: {
			type: Function,
			required: false
		},
		selectDate: {
			type: Array,
			required: false
		},
		formChanged: {
			type: Function,
			required: false
		},
		formChangedPlanningView: {
			type: Function,
			required: false
		},
		dataPlanningSelect: {
			type: Array,
			required: false
		},
		showModal: {
			type: Function,
			required: false
		},
		isNavigateInitDate: {
			type: Boolean,
			default: false,
			required: false
		},
		dataGlobal: {
			type: Array,
			default: () => [],
			required: false
		}
	},
	data: () => {
		const { groupByDate, groupByPerson, groupByStatus, planningView } = FILTER_STRING_TIME_SHEET;
		const { timecards_One, timesheets_Two } = NAME_CARDS_TIMESHEET_EFC;
		return {
			filters: '',
			filter: '',
			allDay: {
				value: -1
			},
			currentDay: {},
			currentForm: {
				value: 0,
				text: 'Select Month'
			},
			groupActionCell: '',
			groupByActionValue: null,
			groupByPerson,
			groupByDate,
			planningView,
			groupByStatus,
			selected: '',
			setPage: 0,
			timecards_One,
			timesheets_Two,
			reset: true,
			notReset: false,
			department: '',
			allDepartements: [],
			selectedUser: [],
			validationDepartments: [],
			DateDefaultSelect: {},
			Days: [],
			currentMonth: moment().add(1, 'month').startOf('month'),
			dateNow: {
				text: moment(Date.now()).format('MMMM YYYY'),
				value: moment(Date.now()).format('MM/YYYY')
			},
			dataToDeleteAllPlanning: [],
			openModalDeleteAll: false
		};
	},
	created() {
		this.filters = '';
		this.initCheckBox();
		this.getDepartment();
		this.initDays();
	},
	computed: {
		setSelectDate() {
			return this.selectDate;
		},
		flattenDays() {
			return this.Days.flat();
		},
		MonthList() {
			const langCurrent = this.lang;
			moment.locale(langCurrent);
			const months = [];
			let startMonth = moment(store.getCurrentProjectConfig().startDate).format('YYYY-MM-DD');
			let month = moment(startMonth);

			while (month.isSameOrBefore(this.currentMonth)) {
				months.push({
					text: month.format('MMMM YYYY'),
					value: month.format('MM/YYYY')
				});
				month.add(1, 'month');
			}

			return months.reverse();
		},
		optionsMonth: {
			get() {
				let data =
					this.setSelectDate !== undefined &&
					this.setSelectDate.map((item) => ({
						value: item.date === undefined ? moment(item.startDate).format('MM/YYYY') : moment(item.date).format('MM/YYYY'),
						text:
							item.date === undefined
								? this.dateToLiteral(item.startDate).charAt(0).toUpperCase() + this.dateToLiteral(item.startDate).substring(1)
								: this.dateToLiteral(item.date).charAt(0).toUpperCase() + this.dateToLiteral(item.date).substring(1)
					}));
				let uniqueSet = new Set(data.map((item) => JSON.stringify(item)));
				let _date = Array.from(uniqueSet)
					.map((item) => JSON.parse(item))
					.sort((a, b) => {
						const dateA = new Date(a.value);
						const dateB = new Date(b.value);
						return dateB - dateA;
					});
				_date = [this.DateDefaultSelect, ..._date];
				let targetObj = _date[0];
				let array = [targetObj];
				for (let i = 0; i < _date.length; i++) {
					if (_date[i].text !== targetObj.text) {
						array.push(_date[i]);
					}
					targetObj = _date[i];
				}
				return array;
			},
			set(array) {
				return array;
			}
		},
		setGroupByFilter: {
			get() {
				this.selected = this.groupByFilter;
				return this.selected;
			},
			set(val) {
				this.selected = val;
				return this.selected;
			}
		}
	},
	watch: {
		filter: {
			handler(val) {
				this.filters = val;
				if (val.length === 0) {
					this.onSearch(val, this.notReset, this.id);
				}
			},
			immediate: true,
			deep: true
		},
		setGroupByFilter: {
			handler(val) {
				this.filter = '';
				this.setGroupByFilter = val;
			},
			immediate: true,
			deep: true
		},
		setFilter: {
			handler() {
				if (this.isNavigateInitDate) {
					const langCurrent = this.currentLang;
					let _date = moment(Date.now()).locale(`${langCurrent}`).format('MM/YYYY');
					let text = moment(_date, 'MM/YYYY').locale(`${langCurrent}`).format('MMMM YYYY');
					let obj_date = {
						text: text,
						value: _date
					};
					this.currentForm = obj_date;
					return this.currentForm;
				} else {
					return this.currentForm;
				}
			},
			immediate: true,
			deep: true
		},
		department: {
			async handler(newVal) {
				if (newVal === null) {
					this.filter = '';
					let data = {
						value: null,
						message: null,
						id: null
					};
					this.$emit('handleSelectDepartementChange', data);
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		currentForm: {
			handler(newVal) {
				if (newVal === null) {
					let data = {
						value: moment(Date.now()).format('MM/YYYY'),
						text: moment(Date.now()).format('MMMM YYYY')
					};
					this.selecteMonth(data, this.timecards_One);
					this.currentForm = data;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		handleCloseAllDeleteModal() {
			this.openModalDeleteAll = false;
		},
		onOpenModalToAllDelete() {
			this.openModalDeleteAll = true;
		},
		async handleDeleteAllPlanning(e) {
			let arraysId = this.dataToDeleteAllPlanning
				.map((item) => item.data)
				.flat()
				.map((item) => +item.id);
			let responses = [];
			for (let id of arraysId) {
				let result = await deleteTimeCardsTsDay(id);
				responses.push(result);
			}
			if (responses.every((response) => response === true)) {
				this.createToastForMobile(this.FormMSG(25, 'Success'), this.FormMSG(24, 'Timesheets item delete successfully'), '', 'success', true);
				this.$emit('refersh-on-delete-not-submitted-all', e);
			}
			this.openModalDeleteAll = false;
		},
		filterByDate(date, data) {
			if (data.length !== 0) {
				return data.filter((item) => moment(item.date).format('DD/MM/YYYY') === date && item.validated === 0);
			}
			return [];
		},
		filterUserId(userInfo, arrays) {
			if (arrays.length !== 0) {
				return arrays
					.filter((item) => +item.user.id === +userInfo.userId && moment(item.date).format('DD/MM/YYYY') === userInfo.date && item.validated === 0)
					.map((item) => ({ date: moment(item.date).format('DD/MM/YYYY'), id: item.id, user: item.user }));
			}
			return [];
		},
		countDataToUpdate(_data) {
			this.dataToDeleteAllPlanning = [];
			if (_data !== undefined) {
				let data = _data.map((item) => ({
					date: item.date,
					userId: item.userId,
					fullName: item.fullName,
					data: item.dataAdd !== undefined ? item.dataAdd.tsDays : []
				}));
				let globalUserIdsUnique = [...new Set(data.map((item) => ({ userId: item.userId, date: item.date })))].map((item) => ({
					date: item.date,
					userId: item.userId
				}));
				let filterDataValid = this.dataGlobal.map((item) => item.tsDays).flat();
				let res = globalUserIdsUnique
					.map((item) => ({ ...item, data: this.filterUserId(item, filterDataValid) }))
					.map((item) => ({ ...item, fullName: item.data.length !== 0 ? item.data[0].user.name + ' ' + item.data[0].user.firstName : '' }))
					.filter((item) => item.data.length !== 0);
				let result = res.reduce((accumulator, current) => {
					let existingItem = accumulator.find((item) => item.userId === current.userId && item.fullName === current.fullName);
					if (existingItem) {
						existingItem.data.push(...current.data);
					} else {
						accumulator.push({
							data: [...current.data],
							userId: current.userId,
							fullName: current.fullName
						});
					}
					return accumulator;
				}, []);
				this.dataToDeleteAllPlanning = result;
				let size = result.map((item) => item.data).flat().length;
				return '(' + size.toString().padStart('2', '0') + ')';
			}
			return '(00)';
		},
		groupByData() {
			if (this.openModalDeleteAll) {
				return this.dataToDeleteAllPlanning;
			}
		},
		initDays(months) {
			let curMonthAndYear;
			this.Days = [];
			this.allDay.text = this.FormMSG(111, 'All day');
			this.Days.push(this.allDay);
			if (!months) {
				curMonthAndYear = moment(Date.now()).format('YYYY-MM');
			} else {
				curMonthAndYear = moment(months, 'MM/YYYY').format('YYYY-MM');
			}
			const monthStart = moment(`${curMonthAndYear}-01`);
			const numDays = monthStart.daysInMonth();
			const langCurrent = this.currentLang;

			const dates = [];
			for (let day = 1; day <= numDays; day++) {
				const date = moment(`${curMonthAndYear}-${day}`);
				const formattedDate = {
					text: date.locale(langCurrent).format('DD - dddd'),
					value: date.format('DD/MM/YYYY')
				};
				dates.push(formattedDate);
			}
			this.Days.push(dates);
			this.currentDay = this.allDay;
		},
		handleSelectedDay(e) {
			this.$emit('day:Selected', e.value);
		},
		selecteMonth(e, id) {
			let data = {
				e,
				id
			};
			this.initDays(e.value);
			this.$emit('month:Selected', data);
		},
		initReset() {
			this.filters = '';
			this.filter = '';
			return this.onSearch(this.filters, this.reset, this.id);
		},
		dateToLiteral(timesheetDate) {
			const langCurrent = this.currentLang;
			const fullLiteralDate = moment(timesheetDate).locale(`${langCurrent}`).format('MMMM YYYY');

			return fullLiteralDate;
		},
		initCheckBox() {
			if (this.id === this.timecards_One) {
				this.selected = this.planningView;
			}
			if (this.id === this.timesheets_Two) {
				this.selected = this.groupByDate;
			}
			this.currentForm = {
				value: moment(Date.now()).format('MM/YYYY'),
				text: this.dateToLiteral(Date.now()).charAt(0).toUpperCase() + this.dateToLiteral(Date.now()).substring(1)
			};
			this.DateDefaultSelect = {
				value: moment(Date.now()).format('MM/YYYY'),
				text: this.dateToLiteral(Date.now()).charAt(0).toUpperCase() + this.dateToLiteral(Date.now()).substring(1)
			};
		},
		async getDepartment() {
			await this.getValidationDepartments();
			let result = await getDepartments(false, false);

			if (this.validationDepartments.length === 1 && this.validationDepartments[0].value === -1) {
				this.allDepartements = result.filter((department) => department.value !== 0);
				return;
			}

			if (this.validationDepartments.length > 0) {
				let newAllDepartments = [];
				for (let i = 0; i < this.validationDepartments.length; i++) {
					const element = this.validationDepartments[i];

					result.map((res) => {
						if (parseInt(res.value, 10) === parseInt(element.value, 10)) {
							newAllDepartments.push(res);
						}
					});
				}

				this.allDepartements = newAllDepartments.filter((department) => department.value !== 0);
				return;
			}
		},
		async getValidationDepartments() {
			const result = await GetEncodingValidation(store.getCurrentProjectConfig().id, store.userID());
			this.validationDepartments = result.department;
		},
		async handleSelectDepartement(item) {
			if (this.filter.length !== 0) {
				this.filter = '';
				this.filters = '';
				setTimeout(() => {
					this.department = item.value;
					this.$emit('handleSelectDepartementChange', item);
				}, 1500);
			} else {
				this.$emit('handleSelectDepartementChange', item);
			}
		},
		setCurrentForm(boolean) {
			this.$emit('setIsNavigateInitDate', boolean);
		}
	}
};
</script>
<style scoped>
@keyframes sonar {
	0% {
		transform: scale(0.6);
		opacity: 1;
	}
	100% {
		transform: scale(1.2);
		opacity: 0;
	}
}
@keyframes pulse {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(0.9);
	}
	50% {
		transform: scale(1);
	}
	80% {
		transform: scale(0.6);
	}
	100% {
		transform: scale(1);
	}
}
.status-not-submitted {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #fcede9;
	border: 1px solid #ea4e2c;
	animation: pulse 1.5s infinite;
}
.status-not-submitted:after {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #ea4e2c;
	animation: sonar 1.5s infinite;
}
.status-info {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #e7f2ff;
	border: 1px solid #225cbd;
}
.status-pending {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #fbf5e5;
	border: 1px solid #cd9a16;
}
.status-validated {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #ecf6e8;
	border: 1px solid #3c7442;
}
.status-refused {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #ffb0b0;
	border: 1px solid #980000;
}
.status-sent {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #d5d6d7;
	border: 1px solid #687887;
}
.gp-5 {
	gap: 5px;
}
.gap-1 {
	gap: 10px;
}
.border-1 {
	border-radius: 5px;
	width: 60px;
}
.border-rd {
	border: 2px solid #dfdfdf;
	border-radius: 3px;
}
.border-lf {
	border-left: 2px solid #dfdfdf;
	padding: 0px 10px;
}
.text-1 {
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.01em;
	color: rgba(6, 38, 62, 0.74);
}
</style>
