<template>
	<div class="clearfix mb-2">
		<b-row>
			<b-col>
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
					{{ FormMSG(77, 'Timesheets - Entry for crew') }}
				</h1>
			</b-col>
			<b-col md="auto">
				<b-button @click="showModal()" class="btn btn-success pull-right block-sm">
					{{ FormMSG(3, 'Add to timesheet') }}
				</b-button>
			</b-col>
		</b-row>
		<b-row>
			<time-card-for-crew-modal
				:editData="editData"
				:isOpen="Open"
				:showModal="showModal"
				:hideModal="hideModal"
				:isActionCrud="isActionCrud"
				:addDataFunc="addDataFunc"
			/>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import TimeCardForCrewModal from '../../timesheet/TimeCardForCrewModal.vue';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { ACTION_EFC_TIMESHEET } from '@/utils/utils';
export default {
	name: 'MinitHeaderTimesheet',
	components: {
		TimeCardForCrewModal
	},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {},
	data: () => {
		const { addData } = ACTION_EFC_TIMESHEET;
		return {
			editData: {},
			isActionCrud: '',
			addData,
			Open: false
		};
	},
	props: {
		addDataFunc: {
			type: Function,
			required: false
		}
	},
	watch: {},
	computed: {},
	created() {},
	methods: {
		showModal() {
			this.isActionCrud = this.addData;
			this.Open = true;
		},
		hideModal() {
			this.Open = false;
		}
	}
};
</script>
