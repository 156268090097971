var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-row justify-content-center align-items-start",
      staticStyle: {
        "background-color": "#f5f7f9",
        padding: "16px",
        "border-radius": "8px",
      },
    },
    [
      _c(
        "b-col",
        { staticClass: "p-0" },
        [
          _c(
            "b-row",
            { staticClass: "d-flex align-items-center" },
            [
              _c("b-col", { attrs: { cols: "10" } }, [
                _c("h1", { staticClass: "h-text-color" }, [
                  _vm._v(_vm._s(_vm.convertTextDate(_vm.showDetailData.date))),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex justify-content-start align-items-center",
                  attrs: { cols: "6" },
                },
                [
                  !_vm.isFilmSingle
                    ? _c("h6", { staticClass: "h-text-color p-1" }, [
                        _c("span", [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(257, "Encoded by:")) +
                              "\n\t\t\t\t\t\t"
                          ),
                          _c("b", [
                            _vm._v(_vm._s(_vm.encoder(_vm.showDetailData))),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-end align-items-center",
                  attrs: { cols: "6" },
                },
                [
                  !_vm.isFilmSingle
                    ? _c("h6", { staticClass: "p-1" }, [
                        _c("div", { staticClass: "name-title" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.showDetailData.user.name) +
                              " " +
                              _vm._s(_vm.showDetailData.user.firstName) +
                              "\n\t\t\t\t\t\t"
                          ),
                          _vm.showDetailData.user.functionName.length > 0
                            ? _c("span", { staticClass: "divider-dot" })
                            : _vm._e(),
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.showDetailData.user.functionName) +
                              "\n\t\t\t\t\t"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("b-col", [
                _c("div", { staticClass: "details-timesheet" }, [
                  _c("ul", [
                    _c("li", [
                      _c("div", { staticClass: "column-1" }, [
                        _vm._v(_vm._s(_vm.FormMSG(183, "Status"))),
                      ]),
                      _c("div", { staticClass: "column-2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "wrap-status d-flex justify-content-end align-items-end",
                          },
                          [
                            _c(
                              "div",
                              {
                                class: `status ${_vm.classStatus(
                                  _vm.showDetailData.validated
                                )}`,
                                staticStyle: { "font-size": "0.675rem" },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.getLabelStatus(
                                        _vm.showDetailData.validated
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    (_vm.showDetailData.validated === 4 ||
                      _vm.showDetailData.validated === 16) &&
                    _vm.showDetailData.comment &&
                    _vm.showDetailData.comment.length > 0
                      ? _c(
                          "li",
                          {
                            staticClass: "timesheet-comment border-0 rounded-0",
                          },
                          [
                            _c("p", [
                              _vm._v(_vm._s(_vm.showDetailData.comment)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c("li", [
                      _c("div", { staticClass: "column-1" }, [
                        _vm._v(_vm._s(_vm.FormMSG(16, "Worked"))),
                      ]),
                      _c("div", { staticClass: "column-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.setWorked(
                              _vm.showDetailData.hours,
                              _vm.showDetailData.minutes
                            )
                          )
                        ),
                      ]),
                    ]),
                    _vm.showWeeklyOvt() ||
                    _vm.showWeeklyOvt1() ||
                    _vm.showWeeklyOvt2()
                      ? _c("li", [
                          _c("div", { staticClass: "column-1" }, [
                            _vm._v(_vm._s(_vm.FormMSG(175, "Overtime"))),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "column-2 d-flex flex-row justify-content-end align-items-center",
                            },
                            [
                              _vm.setTotalOvt(
                                [
                                  _vm.showDetailData.hoursOvertime,
                                  _vm.showDetailData.hoursOvertime1,
                                  _vm.showDetailData.hoursOvertime2,
                                ],
                                [
                                  _vm.showDetailData.minutesOvertime,
                                  _vm.showDetailData.minutesOvertime1,
                                  _vm.showDetailData.minutesOvertime2,
                                ]
                              ) !== "00:00"
                                ? _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName:
                                            "v-b-tooltip.hover.left.html",
                                          value: _vm.tooltipContentOvertime(
                                            _vm.showDetailData
                                          ),
                                          expression:
                                            "tooltipContentOvertime(showDetailData)",
                                          modifiers: {
                                            hover: true,
                                            left: true,
                                            html: true,
                                          },
                                        },
                                      ],
                                      staticClass:
                                        "btn-transparent text-color-rhapsody-in-blue",
                                    },
                                    [
                                      _c(
                                        _vm.getLucideIcon(_vm.ICONS.INFO.name),
                                        {
                                          tag: "component",
                                          attrs: {
                                            color: _vm.ICONS.INFO.color,
                                            size: 16,
                                          },
                                        }
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("div", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.setTotalOvt(
                                        [
                                          _vm.showDetailData.hoursOvertime,
                                          _vm.showDetailData.hoursOvertime1,
                                          _vm.showDetailData.hoursOvertime2,
                                        ],
                                        [
                                          _vm.showDetailData.minutesOvertime,
                                          _vm.showDetailData.minutesOvertime1,
                                          _vm.showDetailData.minutesOvertime2,
                                        ]
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c("li", [
                      _c("div", { staticClass: "column-1" }, [
                        _vm._v(_vm._s(_vm.FormMSG(180, "Start"))),
                      ]),
                      _c("div", { staticClass: "column-2" }, [
                        _vm._v(
                          _vm._s(_vm.formatTime(_vm.showDetailData.strTime))
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("div", { staticClass: "column-1" }, [
                        _vm._v(_vm._s(_vm.FormMSG(181, "End"))),
                      ]),
                      _c("div", { staticClass: "column-2" }, [
                        _vm._v(
                          _vm._s(_vm.formatTime(_vm.showDetailData.endTime))
                        ),
                      ]),
                    ]),
                    _vm.showTransport() ||
                    _vm.showNightHours() ||
                    _vm.showTooEarlyHours()
                      ? _c("li", [
                          _c("div", { staticClass: "column-1" }, [
                            _vm._v(_vm._s(_vm.FormMSG(20, "Transport"))),
                          ]),
                          _c("div", { staticClass: "column-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.setHours(
                                  _vm.showDetailData.hoursTransportTimePaid
                                )
                              ) +
                                ":" +
                                _vm._s(
                                  _vm.showDetailData.minutesTransportTimePaid
                                )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("li", [
                      _c("div", { staticClass: "column-1" }, [
                        _vm._v(_vm._s(_vm.FormMSG(21, "Night"))),
                      ]),
                      _c("div", { staticClass: "column-2" }, [
                        _vm._v(
                          _vm._s(_vm.setHours(_vm.showDetailData.hoursNight)) +
                            ":" +
                            _vm._s(_vm.showDetailData.minutesNight)
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("div", { staticClass: "column-1" }, [
                        _vm._v(_vm._s(_vm.FormMSG(182, "Allowances"))),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "column-2 d-flex flex-row justify-content-end align-items-end",
                          staticStyle: { gap: "4px" },
                        },
                        [
                          _vm.showDetailData.totAllowances > 0
                            ? _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.left.html",
                                      value: _vm.tooltipContentAllowance(
                                        _vm.showDetailData
                                      ),
                                      expression:
                                        "tooltipContentAllowance(showDetailData)",
                                      modifiers: {
                                        hover: true,
                                        left: true,
                                        html: true,
                                      },
                                    },
                                  ],
                                  staticClass:
                                    "btn-transparent text-color-rhapsody-in-blue",
                                },
                                [
                                  _c(_vm.getLucideIcon(_vm.ICONS.INFO.name), {
                                    tag: "component",
                                    attrs: {
                                      color: _vm.ICONS.INFO.color,
                                      size: 16,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.formatCurrency(
                                  _vm.showDetailData.totAllowances
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c("li", [
                      _c("div", { staticClass: "column-1" }, [
                        _vm._v(_vm._s(_vm.FormMSG(22, "Anticipated"))),
                      ]),
                      _c("div", { staticClass: "column-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.setHours(_vm.showDetailData.hoursTooEarly)
                          ) +
                            ":" +
                            _vm._s(_vm.showDetailData.minutesTooEarly)
                        ),
                      ]),
                    ]),
                    _vm.showPreCall()
                      ? _c("li", [
                          _c("div", { staticClass: "column-1" }, [
                            _vm._v(_vm._s(_vm.FormMSG(222, "Precall"))),
                          ]),
                          _c("div", { staticClass: "column-2" }, [
                            _vm._v(_vm._s(_vm.showDetailData.beforeCallTime)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.showLunchPerDiem() ||
                    _vm.showHotelPerDiem() ||
                    _vm.showDinnerPerDiem() ||
                    _vm.showAbroadPerDiem()
                      ? _c("li", [
                          _c("div", { staticClass: "column-1" }, [
                            _vm._v(_vm._s(_vm.FormMSG(220, "Per Diem"))),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "column-2 d-flex flex-row justify-content-end align-items-center",
                              staticStyle: { gap: "4px" },
                            },
                            [
                              _vm.showDetailData.totPerDiem > 0
                                ? _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName:
                                            "v-b-tooltip.hover.left.html",
                                          value: _vm.tooltipContentPerIdem(
                                            _vm.showDetailData
                                          ),
                                          expression:
                                            "tooltipContentPerIdem(showDetailData)",
                                          modifiers: {
                                            hover: true,
                                            left: true,
                                            html: true,
                                          },
                                        },
                                      ],
                                      staticClass:
                                        "btn-transparent text-color-rhapsody-in-blue",
                                    },
                                    [
                                      _c(
                                        _vm.getLucideIcon(_vm.ICONS.INFO.name),
                                        {
                                          tag: "component",
                                          attrs: {
                                            color: _vm.ICONS.INFO.color,
                                            size: 16,
                                          },
                                        }
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatCurrency(
                                      _vm.showDetailData.totPerDiem
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.showDetailData.dailyTotAllowances > 0 ||
                    _vm.showDetailData.weeklyTotAllowances > 0
                      ? _c("li", [
                          _c("div", { staticClass: "column-1" }, [
                            _vm._v(_vm._s(_vm.FormMSG(221, "Allowances"))),
                          ]),
                          _c("div", { staticClass: "column-2" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-end align-items-center",
                              },
                              [
                                _vm.showDetailData.dailyTotAllowances > 0 ||
                                _vm.showDetailData.weeklyTotAllowances > 0
                                  ? _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName:
                                              "v-b-tooltip.hover.left.html",
                                            value:
                                              _vm.tooltipTsContentAllowance(
                                                _vm.showDetailData
                                              ),
                                            expression:
                                              "tooltipTsContentAllowance(showDetailData)",
                                            modifiers: {
                                              hover: true,
                                              left: true,
                                              html: true,
                                            },
                                          },
                                        ],
                                        staticClass:
                                          "btn-transparent text-color-rhapsody-in-blue",
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Info"), {
                                          tag: "component",
                                          attrs: { color: "#225CBD", size: 16 },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-top": "4px" } },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.getCurrency(
                                            _vm.showDetailData
                                              .dailyTotAllowances +
                                              _vm.showDetailData
                                                .weeklyTotAllowances
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("li", [
                      _c("div", { staticClass: "column-1" }, [
                        _vm._v(_vm._s(_vm.FormMSG(23, "Salary"))),
                      ]),
                      _c("div", { staticClass: "column-2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row align-items-center justify-content-end w-100",
                          },
                          [
                            _c("salary-log", {
                              staticClass: "cursor-pointer",
                              attrs: { item: _vm.showDetailData },
                            }),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.getCurrency(_vm.showDetailData.salary)
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("div", { staticClass: "column-1" }, [
                        _vm._v(_vm._s(_vm.FormMSG(102, "CO2"))),
                      ]),
                      _c("div", { staticClass: "column-2" }, [
                        _vm._v(_vm._s(_vm.getCO2(_vm.showDetailData.kgCoTwo))),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }